import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ChallengeModal = ({
    open,
    setOpen,
    id,
    img,
    title,
    subtitle,
    details,
    prize,
}) => {
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!open) {
            navigate('/challenges');
        }
    }, [open, navigate]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-30"
                onClose={handleClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-primary2 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-20 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center place-items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                <div className="p-6 sm:p-12">
                                    <div className="flow-root">
                                        <div className="float-left font-bold text-lg sm:text-2xl text-primary2 mb-2">
                                            {title}
                                        </div>
                                        <div className="float-right group">
                                            <div
                                                className="flex justify-center content-center p-2 w-8 h-8 border rounded-full bg-primary3 group-hover:cursor-pointer group-hover:bg-primary2 group-hover:text-primary1"
                                                onClick={handleClose}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    className="text-primary2 group-hover:text-white"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="font-bold text-xs sm:text-base text-primary2 mb-4">
                                        {subtitle}
                                    </div>
                                    <img
                                        src={img}
                                        alt="challenge img"
                                        className="object-cover h-64 lg:h-96 w-full rounded-md lg:rounded-xl"
                                    />
                                    <div className="text-xs sm:text-lg text-primary2 my-4">
                                        {details}
                                    </div>
                                    {/* <div className="font-bold text-xs sm:text-lg text-primary2">
                                        Prize: {prize}
                                    </div> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ChallengeModal;
