import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import collabothonLogo from '../../assets/images/logo/logo_inverted_color.svg';
import collabothonLogoTriangle from '../../assets/images/logo/logo_only_triangle.svg';

import AnimatedDiv from '../animation/AnimatedDiv';
import CountdownBanner from './CountdownBanner';
import RegistrationButton from '../registration/RegistrationButton';

const DesktopHero = () => {
    const { height } = useWindowDimensions();
    const [isRegisterVisible, setIsRegisterVisible] = useState(true);
    const [isCodingVisible, setIsCodingVisible] = useState(true);
    const endRegisterDate = '2024-10-16 00:00 GMT+0200';
    const endCodingDate = '2024-10-20 11:30 GMT+0200';

    return (
        // idea: show a big desktop version starting from breaking point "xl" and show a mobile version for everything below
        <>
            <div className="relative h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] bg-image overflow-hidden select-none pointer-events-none">
                {/* Collabothon Logo */}
                <AnimatedDiv
                    child={
                        <img
                            src={collabothonLogo}
                            alt="collabothon logo"
                            className="absolute w-[800px] left-[calc(50%-400px)] xl:w-[1000px] xl:left-[calc(50%-500px)] 2xl:w-[1200px] 2xl:left-[calc(50%-600px)] select-none pointer-events-none"
                        />
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: height * 0.1,
                            transition: { delay: 0.2, duration: 2 },
                            scale: 0.8,
                        },
                        hidden: { opacity: 0, y: height * 0.5 },
                    }}
                    parentClasses={'z-[25] relative'}
                    isBidirectional={false}
                />
                <AnimatedDiv
                    child={
                        <img
                            src={collabothonLogoTriangle}
                            alt="collabothon logo"
                            className="absolute w-[800px] left-[calc(50%-400px)] xl:w-[1000px] xl:left-[calc(50%-500px)] 2xl:w-[1200px] 2xl:left-[calc(50%-600px)] select-none pointer-events-none"
                        />
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: height * 0.1,
                            scale: 0.8,
                            transition: { delay: 0.2, duration: 2 },
                        },
                        hidden: { opacity: 1, y: height * 0.5, scale: 1 },
                    }}
                    parentClasses={'z-[25] relative'}
                    isBidirectional={false}
                />
                {/* Sub Title */}
                <AnimatedDiv
                    child={
                        <div
                            className={
                                'absolute text-center w-[1000px] left-[calc(50%-500px)] pointer-events-auto'
                            }
                        >
                            <div className="mt-8 text-4xl 2xl:text-5xl text-white text-center font-bold transform -skew-x-12 hyphens-none">
                                The international hackaton <br />
                                <span className="inline-block transform skew-x-12">
                                    18-20 October 2024
                                </span>
                            </div>
                            {isRegisterVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-3xl 2xl:text-4xl text-white text-left font-bold hyphens-none">
                                            Time left to register...
                                            <CountdownBanner
                                                isVisible={isRegisterVisible}
                                                setIsVisible={
                                                    setIsRegisterVisible
                                                }
                                                timeTillDate={endRegisterDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <div className="flex md:hidden justify-center items-center">
                                            <RegistrationButton
                                                small
                                                buttonLabel={'Register here!'}
                                            />
                                        </div>
                                        <div className="hidden md:flex justify-center items-center">
                                            <RegistrationButton
                                                buttonLabel={'Register here!'}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isRegisterVisible && isCodingVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-3xl 2xl:text-4xl text-white text-left font-bold hyphens-none">
                                            Coding end in...
                                            <CountdownBanner
                                                isVisible={isCodingVisible}
                                                setIsVisible={
                                                    setIsCodingVisible
                                                }
                                                timeTillDate={endCodingDate}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isRegisterVisible && !isCodingVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-3xl 2xl:text-4xl text-white text-center font-bold hyphens-none">
                                            Thank you for joining <br />
                                            Results will be published soon
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: isMobile ? height * 0.225 : height * 0.225,
                            scale: 1,
                            transition: { delay: 1.5, duration: 0.7 },
                        },
                        hidden: {
                            opacity: 0,
                            y: isMobile ? height * 0.225 : height * 0.3,
                            scale: 1.05,
                        },
                    }}
                    parentClasses={'z-[27] relative'}
                    isBidirectional={false}
                />
            </div>
        </>
    );
};

export default DesktopHero;
