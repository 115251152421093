import React from 'react';

import Privacypolicy from '../components/privacy-policy/Privacypolicy';

const PrivacyPolicyPage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <Privacypolicy />
        </div>
    );
};

export default PrivacyPolicyPage;
