import makeAnImpact from '../../assets/images/make-an-impact.jpg';
import learnThriveGrow from '../../assets/images/learn-thrive-grow.jpg';
import collaborate from '../../assets/images/collaborate.jpg';

export const partnerInfoCardsData = [
    {
        imgSrc: makeAnImpact,
        title: 'MAKE AN IMPACT',
        description:
            "Life is all about making an impact. Isn't it? Now you have this opportunity. Be creative and innovate.",
    },
    {
        imgSrc: learnThriveGrow,
        title: 'LEARN. THRIVE. GROW.',
        description:
            'Sharing knowledge with peers, mentors, innovators, daring people who are not scared to embrace challenges.',
    },
    {
        imgSrc: collaborate,
        title: 'COLLABORATE',
        description:
            "Collaboration is at the heart of our hackathon event. Don't be afraid to meet new people, get involved and thrive together.",
    },
];
