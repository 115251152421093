import React from 'react';

import Headline from '../common/Headline';

const LegalPage = () => {
    return (
        <div className="mx-4 sm:mx-24">
            <Headline
                headline="Imprint"
                classNames="text-primary2"
            />

            <div>
                <h2 className="text-lg md:text-2xl font-bold">
                    {'Commerzbank Aktiengesellschaft '}
                    <br className="xs:hidden" />
                    {'(public limited company)'}
                </h2>
                <p className="my-2">
                    {'Commerzbank Aktiengesellschaft '}
                    <br className="xs:hidden" />
                    {'(public limited company)'}
                    <br />
                    {'Office premises: '}
                    <br className="xs:hidden" />
                    {'Kaiserplatz, 60311 Frankfurt am Main'}
                    <br />
                    {'Postal address: '}
                    <br className="xs:hidden" />
                    {'60261 Frankfurt am Main'}
                </p>
            </div>

            <div className="my-6">
                <div className="font-bold">
                    Your contact for questions to the company
                </div>

                <p className="my-2">
                    <strong>Telephone:</strong> +49 (0)69 1 36 20
                    <br />
                    <strong>E-Mail</strong>
                    <sup>1</sup>:{' '}
                    <a href="mailto:info@commerzbank.com">
                        info@commerzbank.com
                    </a>
                    <br />
                    <strong>Internet:</strong>{' '}
                    https://www.commerzbank.de/portal/en/englisch/
                </p>
            </div>

            <div className="my-6">
                <div className="font-bold">
                    Your contact for questions to the Collabothon 2023 event
                </div>
                <p className="my-2">
                    <strong>E-Mail</strong>
                    <sup>1</sup>:{' '}
                    <a href="mailto:info@commerzbank.com">
                        collabothon@commerzbank.com
                    </a>
                    <br />
                    <strong>Internet:</strong> https://www.collabothon.eu/
                </p>
            </div>

            <div className="my-6">
                <div className="font-bold">
                    Authorized Representatives/Board:
                </div>
                <div>Manfred Knof (Vorsitzender/Chairman),</div>
                <div>
                    Marcus Chromik, Michael Kotzbauer, Sabine Mlnarsky, Jörg
                    Oliveri del Castillo-Schulz, Bettina Orlopp, Thomas
                    Schaufler
                </div>
            </div>

            <div className="my-6">
                <div className="font-bold">Registration Court:</div>
                <div>Local Court Frankfurt am Main</div>
                <div>Commercial register number: HRB 32000</div>
            </div>

            <div className="my-6">
                <div className="font-bold">VAT Registration Number:</div>
                <div>DE – 114 103 514</div>
            </div>

            <div className="my-6">
                <div className="font-bold">
                    European online dispute resolution platform
                </div>
                <p className="my-2">
                    The European Commission has set up a European Online Dispute
                    Resolution Platform (OS platform) at{' '}
                    <a
                        href="http://ec.europa.eu/consumers/odr/"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                    >
                        http://ec.europa.eu/consumers/odr/
                    </a>{' '}
                    <br />A consumer may use the OS platform for the
                    out-of-court settlement of a dispute arising from online
                    contracts with a company established in the EU.
                </p>
            </div>

            <p className="my-6 text-xs">
                <sup>1</sup>
                Please note that your e-mail communication with us is not
                encrypted. Therefore, the possibility that third parties may
                read your emails cannot be excluded. It is in your own interest
                that you do not refer to specific business details in your
                communications. We do not accept transfers, security orders or
                time-bound orders or instructions by email.
            </p>
        </div>
    );
};

export default LegalPage;
