export const handleScrollById = (id, offset) => {
    // get the element by id
    const element = document.getElementById(id);

    if (element) {
        // define your offset (depending on your header)
        const headerOffset = offset;
        // get your elements top side position
        const elementPosition = element?.getBoundingClientRect().top;
        // calculate your elements required offset position
        const offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;
        // use window.scrollTo to smoothly scroll to your elements offseted position
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
    // // can be used when no offset calculation is required
    // else {
    //   elem?.scrollIntoView({
    //     behavior: "smooth",
    //   });
    // }
};

export const openInNewTab = (url) => {
    if(localStorage.getItem("analyticsConsent") === "true"){
     // Create the Adform tracking script element
     const script = document.createElement('script');
     script.type = 'text/javascript';
     script.async = true;
     script.src = 'https://a2.adform.net/banners/scripts/st/trackpoint-async.js';
 
     // Append the script to the document's head
     const x = document.getElementsByTagName('script')[0];
     x.parentNode.insertBefore(script, x);
 
     // Define the tracking configuration
     window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : [];
     window._adftrack.push({
     HttpHost: 'track.adform.net',
     pm: 2354148,
     divider: encodeURIComponent('|'),
     pagename: encodeURIComponent('Hackathon_registrace-open')
     });
 
     // Create the noscript element for tracking
     const noscript = document.createElement('noscript');
     noscript.innerHTML = `
     <p style="margin:0;padding:0;border:0;">
         <img src="https://track.adform.net/Serving/TrackPoint/?pm=2354148&ADFPageName=Hackathon_registrace-open&ADFdivider=|" width="1" height="1" alt="" />
     </p>
     `;
 
     // Append the noscript element to the document's body
     document.body.appendChild(noscript);
    
    }
    window.open(url, '_blank', 'noreferrer');

};
