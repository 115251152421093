import React from 'react';

import OrderOfEvents from '../../order-of-events/OrderOfEvents';
import { eventsData } from '../../order-of-events/orderOfEventsData';

const RegistrationInfo = () => {
    return (
        <div className="bg-white \">
            <h1 className="text-center uppercase font-bold text-4xl md:text-5xl lg:text-6xl mb-12 sm:mb-24 w-10/12 m-auto z-10 text-primary2 pt-20">
                HOW TO JOIN{' '}
                <span className="text-[#FF018C] font-extrabold inline-block transform -skew-x-12">
                    COLLABOTHON 2024
                </span>
            </h1>
            <div className="px-6 xl:px-0 w-10/12 xl:w-8/12 mx-auto pb-20">
                <OrderOfEvents data={eventsData} />
            </div>
        </div>
    );
};

export default RegistrationInfo;
