import PeterPhoto  from '../../assets/images/testimonials/PeterTestimonials.jpeg'
import KrzysztofPhoto from '../../assets/images/testimonials/KrzysztofTestimonials.jpeg'
import WeronikaPhoto from '../../assets/images/testimonials/WeronikaTestimonials.png'

export const testimonialData = [
    {
        id: '1',
        title: 'Peter Olszewski',
        subtitle: 'Experienced Collabothon Participant',
        description: `I wasn't sure if I'd enjoy such an event at first. Yet, the thrill you get from working on a vast and complicated problem with your team is simply amazing. The atmosphere, the people, the help we got and new friendships we made along the way - it all led to our success. We had fun and coded till the last deadline. In the end, it's all about people and having fun together. There is no other experience like Collabothon, so see you next year!`,
        imgSrc: PeterPhoto,
    },
    {
        id: '2',
        title: 'Krzysztof Wiedeński',
        subtitle: 'Previous edition participant',
        description: `The last edition of Collabothon was my first one in which I took part. I met a lot of amazing people there. The organization of the event was great, combining four independent locations into one event. Personally, I think I learned a lot there and had a lot of fun!`,
        imgSrc: KrzysztofPhoto,
    },
    {
        id: '3',
        title: 'Weronika Borucka',
        subtitle: 'Last-Edition Collabothon Participant',
        description: `Registering for Collabothon 2023, I didn't expect such a great experience - excellent organization, ample space to think and rest, and incredibly engaging challenges that kept us awake almost all night. The most valuable aspect for me (besides the grand prize 📷) was meeting inspiring people, with whom I still have good relationships to this day. I highly recommend it to everyone, even if you don't have previous hackathon experience.`,
        imgSrc: WeronikaPhoto,
    },
]