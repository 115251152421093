import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Headline from '../common/Headline';
import {ChallengesData} from './challengesData';
import ChallengeCard from './ChallengeCard';
import CustomCarousel from '../custom-carousel/CustomCarousel';
import ChallengeModal from './ChallengeModal';
import { useEffect } from 'react';

const Challenges = () => {
    let { challengeId } = useParams();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        img: 'Placeholder Image Source',
        title: 'Placeholder Title',
        subtitle: 'Placeholder Subtitle',
        details: 'Placeholder Details',
        prize: 'Placeholder Prize',
    });

    const showModal = () => {
        var selectedChallenge;
        if (ChallengesData.some((el) => el.id === challengeId)) {
            selectedChallenge = ChallengesData.find(
                (ele) => ele.id === challengeId
            );
        }
        setModalInfo({
            img: selectedChallenge.imgSrc,
            title: selectedChallenge.title,
            subtitle: selectedChallenge.subtitle,
            details: selectedChallenge.modalDetails,
            prize: selectedChallenge.prize,
        });
        setModalIsOpen(true);
    };

    useEffect(() => {
        if (
            challengeId === undefined ||
            ChallengesData.some((el) => el.id === challengeId)
        ) {
            challengeId && showModal();
        }
    });

    return (
        <div>
            <ChallengeModal
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                img={modalInfo.img}
                title={modalInfo.title}
                subtitle={modalInfo.subtitle}
                details={modalInfo.details}
                prize={modalInfo.prize}
            />

            <Headline
                headline="Challenge"
                classNames="text-primary2"
            />
            <div className="flex justify-center items-center mb-16">
                {/* stacked view for bigger screen sizes */}
                <div className="hidden lg:block w-[100%] md:w-[75%]">
                    {ChallengesData.map((challenge) => {
                        return (
                            <ChallengeCard
                                key={challenge.id}
                                data={challenge}
                                onClick={() =>
                                    navigate(`/challenges/${challenge.id}`)
                                }
                            />
                        );
                    })}
                </div>
                {/* carousel view for smaller screen sizes */}
                <div className="lg:hidden w-[100%] md:w-[90%]">
                    <CustomCarousel>
                        {ChallengesData.map((challenge) => {
                            return (
                                <ChallengeCard
                                    key={challenge.id}
                                    data={challenge}
                                    onClick={() =>
                                        navigate(`/challenges/${challenge.id}`)
                                    }
                                />
                            );
                        })}
                    </CustomCarousel>
                </div>
            </div>
        </div>
    );
};

export default Challenges;
