import React from 'react';

import Headline from '../common/Headline';
import '../privacy-policy/Privacypolicy.css';

function Privacypolicy() {
    return (
        <div className="mx-4 sm:mx-24">
            <p className="text-box">
                <Headline
                    headline="Data Privacy Policy"
                    classNames="text-primary2"
                />
                Below you will find information on the processing of personal
                data as well as on the rights arising from the provisions on the
                protection of personal data, namely the content of Regulation
                (EU) 2016/679 of the European Parliament and the Council as of
                27 April 2016 on the protection of natural persons with regard
                to the processing of personal data and on the free movement of
                such data, and repealing Directive 95/46/EC (the so-called
                General Data Protection Regulation, GDPR) as of 27 April 2016
                (EU Journal of Laws L 119, p. 1).
                <br />
                <br />
                The personal data to be processed and the extent of its usage
                depend on the services requested or agreed, and therefore not
                all parts of this information will be relevant to you.
                <br />
                <br />
                Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział w Polsce
                treats the protection of your personal data very seriously and
                respects the principles of data protection, in particular the
                provisions of the General Data Protection Regulation (GDPR).
                Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział w Polsce
                informs that the protection of personal data is of utmost
                importance for us, and therefore we guarantee the
                confidentiality of all personal data made available.
                <br />
                <br />
                <p>
                    Personal data shall only be processed if the data subject
                    has given his/her explicit consent, when it is necessary to
                    perform the contract or which processing is authorised or
                    required by the GDPR or another law.
                </p>
                <br />
                <h2 className="text-lg font-bold mb-4">
                    Processing personal data
                </h2>
                <ul className="list-disc list-outside pl-8">
                    <li>
                        The website www.collabothon.eu collects information
                        about cookies, IP address, browser type, language,
                        operating system type, Internet service provider, time
                        and date, locations as well as information sent to the
                        website via a contact form.
                    </li>
                    <li>
                        The administrator of all personal data obtained via the
                        website www.collabothon.eu referred to in point 1 above
                        is Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział
                        w Polsce entered in the Register of Entrepreneurs of the
                        National Court Register kept by the District Court for
                        Łódź Śródmieście in Łódź, XX Commercial Division of the
                        National Court Register under no. KRS: 0000631053, NIP:
                        1050002579, REGON: 36510904, tel. +48 42 208 82 02,
                        email: office.lodz@commerzbank.com
                    </li>
                    <li>
                        Your personal data will be processed for the purposes
                        and to the extent which is technically necessary to
                        enable correct usage of the website, monitor and verify
                        how you use our site as well as to conduct traffic
                        analysis on the website of the data administrator.
                    </li>
                    <li>
                        Article 6(1)(a) of the GDPR is the legal basis for
                        processing your personal data.
                    </li>
                    <li>
                        In accordance with Article 6(1)(a) of the GDPR, data
                        submission is voluntary, and failure to provide the data
                        will prevent using the website www.collabothon.eu.
                    </li>
                    <li>
                        Your personal data shall not be made available to other
                        entities for marketing purposes and shall not be
                        transmitted to a third country. Your personal data may
                        be shared with other recipients, such as those who
                        process personal data on behalf of the data
                        administrator, as well as other parties involved in the
                        process of organising the business activities of the
                        administrator as a provider of IT systems with which the
                        administrator and Google work together to use Google
                        Analytics, banks and courier companies, postal
                        operators, tax office, social insurance company and
                        other entities entitled under the law. The administrator
                        reserves the right to disclose the selected information
                        to the competent authorities or third parties who
                        request it, on the basis of the appropriate legislation
                        and in accordance with the applicable law.
                    </li>
                    <li>
                        Your personal data will be stored by 2 years, but no
                        longer than until your consent for processing by the
                        data administrator has been withdrawn.
                    </li>
                    <li>
                        Your personal data will not be used for automated
                        decision-making, including the form of profiling.
                    </li>
                    <li>
                        You shall have the following rights as the persons who
                        have shared personal information:
                        <br />
                        <ul className="list-decimal list-outside pl-8">
                            <li>
                                ) right to access your personal data – Art. 15
                                of the GDPR
                            </li>
                            <li>
                                ) right to correct, rectify personal data – Art.
                                16 of the GDPR
                            </li>
                            <li>
                                ) right to delete personal data – Art. 17 of the
                                GDPR
                            </li>
                            <li>
                                ) right to restrict the processing of personal
                                data – Art. 18 of the GDPR
                            </li>
                            <li>
                                ) right to transfer personal data – Art. 20 of
                                the GDPR
                            </li>
                            <li>
                                ) right to object to the processing of personal
                                data – Art. 21 of the GDPR
                            </li>
                            <li>
                                ) right to withdraw the consent to the
                                processing of personal data at any time,
                                including the consent granted before 25 May
                                2018. Withdrawal of the consent to the data
                                processing shall not affect the lawfulness of
                                the processing which was performed subject to
                                the consent prior to its withdrawal. Therefore,
                                please bear in mind that withdrawal is only
                                effective for the future and does not concern
                                the processing that took place before the
                                withdrawal.
                            </li>
                        </ul>
                    </li>
                    <li>
                        You have also the right to lodge a complaint to
                        Inspector General for the Protection of Personal Data if
                        you consider that the processing of your personal data
                        breaches the provisions arising from the GDPR
                    </li>
                </ul>
                <br />
                <h2 className="text-lg font-bold mb-4">
                    Securing personal data
                </h2>
                <ul className="list-disc list-outside pl-8">
                    <li>
                        Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział w
                        Polsce informs about using SSL/TLS encryption which can
                        be identified by https:// and a lock symbol in the
                        address bar of an Internet browser in order to secure
                        the data of persons using the website.
                    </li>
                    <li>
                        Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział w
                        Polsce applies technical and organisational measures
                        appropriate to the degree of risks posed to the provided
                        services, in particular the measures preventing
                        acquisition and modification of data transmitted on the
                        Internet by unauthorised persons which are necessary to
                        ensure compliance with the rules on the processing of
                        personal data resulting from the GDPR.
                    </li>
                </ul>
                <br />
                <h2 className="text-lg font-bold mb-4">Logging</h2>
                In the server log files, Commerzbank Aktiengesellschaft Spółka
                Akcyjna Oddział w Polsce automatically retrieves and stores some
                of the data which the browser customarily transfers if your
                browser prepares such. This data cannot be attributed to certain
                persons by Commerzbank Aktiengesellschaft Spółka Akcyjna Oddział
                w Polsce nor will it be combined with the data from other
                sources.
                <br />
                <br />
                <h2 className="text-lg font-bold mb-4">Cookies</h2>
                When you visit our websites, we occasionally use cookies, i.e.
                small text files which are archived to your computer and saved
                by your browser. They are used to optimise our portfolio to be
                friendly, effective and secure.
                <br />
                <br />
                Passwords are not saved in cookies. Most cookies we use are the
                so-called session cookies. Once you have completed your visit
                through closing your browser, they are saved for a certain time
                and then overwritten or deleted by the client (e.g. your PC or
                tablet). Our cookies do not contain viruses and will not cause
                any harm to your devices.
                <br />
                <br />
                To continually improve the services and usability of the
                Internet service www.collabothon.eu, our company uses the Google
                Analytics tools provided by Google, Inc. (Google) as well as
                AWstat. Cookies, i.e. text files on a user’s computer, allow
                analysing how you are using the site. These files are used to
                store information, e.g. start time of the current visit and
                whether or not the user has already visited our WWW site and
                from which website.
                <br />
                <br />
                Google Analytics Cookies:
                <ul className="list-disc list-outside pl-8">
                    <li>
                        _UTMA: THIS TYPE IS RECORDED IN THE BROWSER DURING THE
                        FIRST VISIT ON THE WEBSITE. IF THE FILE IS DELETED FROM
                        THE BROWSER, AND THE USER ENTERS THIS SITE LATER, THE
                        NEW FILE _UTMA WILL BE RECORDED UNDER A NEW ID. IT
                        EXPIRES AFTER 2 YEARS. THE FILE IS UPDATED EACH TIME
                        WHEN DATA IS UPLOADED TO GOOGLE ANALYTICS.
                    </li>
                    <li>
                        _UTMB: THIS TYPE IS MADE TO IDENTIFY A USER’S SESSION ON
                        A SITE. IF A BROWSER CANNOT FIND THIS FILE, IT IS
                        RECORDED AS A NEW SESSION. IT EXPIRES AFTER 30 MINUTES.
                        THE FILE IS UPDATED EACH TIME WHEN DATA IS UPLOADED TO
                        GOOGLE ANALYTICS.
                    </li>
                    <li>
                        _UTMC: THIS TYPE DETERMINES WHETHER TO RECORD A SESSION
                        AS NEW FOR THIS USER. IT EXPIRES AT THE MOMENT OF
                        LEAVING THE SITE. THE FILE IS UPDATED EACH TIME WHEN
                        DATA IS UPLOADED TO GOOGLE ANALYTICS.
                    </li>
                    <li>
                        _UTMZ: THIS TYPE IS USED TO CALCULATE FREQUENCY AND HOW
                        AN INTERNET SITE IS ENTERED (DIRECTLY OR THROUGH A
                        REFERENCE LINK; IT ALSO DETERMINES THE TYPE OF BROWSER
                        USED). IT EXPIRES AFTER 6 MONTHS. THE FILE IS UPDATED
                        EACH TIME WHEN DATA IS UPLOADED TO GOOGLE ANALYTICS.
                    </li>
                </ul>
                <br />
                Information on your use of the website contained in cookies
                (including your IP address) is sent to Google and stored on its
                servers in the United States. AWStats Cookies:
                <ul className="list-disc list-outside pl-8">
                    <li>
                        AWSUSER_ID: ZAWIERA LOSOWO WYGENEROWANE ZNAKI. WYGASA PO
                        10 000 GODZIN.
                    </li>
                    <li>
                        AWSSESSION_ID: CONTAINS RANDOMLY GENERATED SIGNS. VALID
                        FOR LESS THAN ONE DAY.
                    </li>
                </ul>
                <br />
                <h2 className="text-lg font-bold mb-4">Using IP address</h2>
                Each computer and device connected to the Internet has a unique
                number called an Internet protocol address (IP address). Since
                these numbers are usually assigned collectively in
                country-dependent blocks, an IP address can often be used to
                identify the state, region, and town from which a computer
                connects to the Internet. Google Analytics records IP addresses
                of website users so that site owners can see what parts of the
                world the site visitors are from.
                <br />
                <br />
                Google uses this information to evaluate how you are using the
                site, compile reports on website activity for website operators,
                and provide other services relating to website activity and
                Internet usage.
                <br />
                <br />
                Google may make this information available to third parties, for
                example in order to comply with the law in force or to process
                this information for Google.
                <br />
                <br />
                Google will not associate your IP address with other data stored
                by Google.
                <br />
                <br />
                You can disable the use of cookies by selecting the appropriate
                settings on your Internet browser.
                <br />
                <br />
                Please note that if you disable cookies, some items on the site
                may not work properly.
                <br />
                <br />
                Using this website constitutes your consent to the processing of
                your data by Google for the purposes and needs described above.
                <br />
                <br />
                <h2 className="text-lg font-bold mb-4">Google Analytics</h2>
                This website uses Google Analytics including its
                marketing-related feature – i.e. the Google Inc. (Google)
                network analysis service. Google Analytics applies cookies which
                are text files stored on your computer allowing to analyse the
                way you are using the website.
                <br />
                <br />
                Google Analytics works only if IP anonymisation (masking) has
                been activated. This means that the IP address of a Google user
                is shortened within the Member States of the European Union or
                in other countries covered by the Agreement for the European
                Economic Area. Only in exceptional cases, for example in the
                event of a technical failure in Europe, the full IP address is
                sent to and shortened by Google servers in the United States.
                <br />
                <br />
                In the event of Google’s IP anonymisation method, the full IP
                address is never recorded on the hard disk since the whole
                anonymisation takes place almost directly upon receipt of the
                request in the working memory. The IP address transmitted by
                your browser is not connected to other Google data.
                <br />
                <br />
                At the request of the administrator of this website, Google will
                use this information to analyse how you are using the website
                pursuant to Article 6(1)(f) of the GDPR in order to compile
                reports on website activities for the site administrator and to
                provide further services associated with the use of the website
                and the Internet.
                <br />
                <br />
                Google may transfer the data held to third parties if so
                required by law or if third parties process the data on the
                recommendation of Google, however, personal data shall not be
                used for that purpose.
                <br />
                <br />
                Google Analytics reports on demographic and interest
                characteristics (such as age groups or interest groups) will use
                data obtained by Google through tailored advertising as well as
                data from the third suppliers. You can avoid storing cookies by
                selecting the appropriate settings on your browser; however,
                please note that you may not be able to use all of the features
                of our websites to the full extent.
                <br />
                <br />
                In addition, you can avoid collecting the data generated by
                cookies and relating to your use of this website (including your
                IP address) as well as processing of this data by Google through
                downloading and installing the browser add-on available below:
                <br />
                <u>
                    <a href="https://tools.google.com/dlpage/gaoptout">
                        Download and install GAOptOut add-on
                    </a>
                </u>
                <br />
                <br />
                You can also prevent Google Analytics from collecting the data
                by clicking on the link below. An “opt-out” cookie will be
                created to prevent your data from being collected in the future
                when you visit this website:
                <br />
                Deactivate Google Analytics
                <br />
                For details on the terms and conditions of data protection and
                use, see
                <br />
                <u>
                    <a href="https://www.google.de/analytics/terms/de.html">
                        https://www.google.de/analytics/terms/de.html
                    </a>
                </u>{' '}
                or
                <br />
                <u>
                    <a href="https://policies.google.com/?hl=pl">
                        {' '}
                        https://policies.google.com/?hl=pl
                    </a>
                </u>
                . <br />
                <br />
                We inform you that Google Analytics uses the “anonymizeIp” code
                on this website to ensure that recording IP addresses (IP
                masking) is anonymised.
                <br />
                <br />
                <p>
                    If you need other information not found in this Data
                    Protection Statement or further details, please contact the
                    Data Protection Officer of Commerzbank Aktiengesellschaft
                    directly: <br />
                    <a href="mailto:datenschutzbeauftragter@commerzbank.com">
                        {' '}
                        datenschutzbeauftragter@commerzbank.com
                    </a>
                </p>
            </p>
        </div>
    );
}

export default Privacypolicy;
