import React, { useContext, useEffect, useState } from 'react';

import ConsentContext from '../context/consentContext';
import DesktopHero from '../components/hero/DesktopHero';
import PartnersSimple from '../components/landing-page/partners/PartnersSimple';
import About from '../components/landing-page/about/About';
import Prizes from '../components/landing-page/prizes/Prizes';
import RegistrationInfo from '../components/landing-page/registration-info/RegistrationInfo';
import MobileHero from '../components/hero/MobileHero';
import ThisYearChallange from '../components/landing-page/challenges/ThisYearChallenge';
import Testimonials from '../components/landing-page/testimonials/Testimonials';
import PreviousEditions from '../components/landing-page/previous-editions/Previous-edtions';
import Discord from '../components/discord/Discord';
import LoadingScreen from '../components/landing-page/loading-page/LoadingScreen';

const LandingPage = () => {
    const { analyticsConsent } = useContext(ConsentContext);
    const { openModal, setOpenModal } = useContext(ConsentContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem("analyticConsent")) {
            setOpenModal(true);
        }
        // if analytics consent is given, console log the value of the consent
        if (localStorage.getItem("analyticConsent") === "true") {
            console.log("After...Local Storage: " + localStorage.getItem("analyticsConsent"));
            // Create the Adform tracking script element
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://a2.adform.net/banners/scripts/st/trackpoint-async.js';

            // Append the script to the document's head
            const x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(script, x);

            // Define the tracking configuration
            window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : [];
            window._adftrack.push({
                HttpHost: 'track.adform.net',
                pm: 2354148,
                divider: encodeURIComponent('|'),
                pagename: encodeURIComponent('Hackathon_LP')
            });

            // Create the noscript element for tracking
            const noscript = document.createElement('noscript');
            noscript.innerHTML = `
            <p style="margin:0;padding:0;border:0;">
                <img src="https://track.adform.net/Serving/TrackPoint/?pm=2354148&ADFPageName=Hackathon_LP&ADFdivider=|" width="1" height="1" alt="" />
            </p>
            `;

            // Append the noscript element to the document's body
            document.body.appendChild(noscript);
        }

        // Set the timer to 2 seconds for loading screen
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        // Clear timer after 2 seconds for loading screen
        return () => clearTimeout(timer);
    }, [analyticsConsent, openModal, setOpenModal]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="bg-white">
            <div className="hidden xl:block">
                <DesktopHero />
            </div>
            <div className="block xl:hidden">
                <MobileHero />
            </div>
            <About />
            <ThisYearChallange />
            <Prizes />
            <PreviousEditions />
            <RegistrationInfo />
            <Discord />
            <PartnersSimple />
            <Testimonials />
        </div>
    );
};

export default LandingPage;
