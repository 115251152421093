import React from 'react';
import { Icon } from '@lsg/components';
import { banking___gem } from '@lsg/icons';

const PrizeContainer = ({ prize, title, subtitle }) => {
    return (
        <div className="px-2 py-6 md:p-12">
            <div
                className="flex justify-center items-center
                            text-white text-3xl md:text-5xl font-extrabold"
            >
                <div className="hidden md:block">
                    <Icon
                        icon={banking___gem}
                        variant="solid"
                        size="medium"
                        color="white"
                        className="mb-0 pb-0 text-white"
                    />
                </div>
                <div className="md:hidden">
                    <Icon
                        icon={banking___gem}
                        variant="solid"
                        size="small"
                        color="white"
                        className="mb-0 pb-0 text-white"
                    />
                </div>
                <div className="ml-4">{prize}</div>
            </div>
            <div
                className="flex justify-center align-middle 
                            text-secondary3 text-xl xs:text-2xl md:text-3xl font-extrabold py-4"
            >
                {title}
            </div>
            <div
                className="flex justify-center align-middle 
                            italic text-secondary2 text-lg xs:text-xl md:text-2xl font-extrabold"
            >
                {subtitle}
            </div>
        </div>
    );
};

export default PrizeContainer;
