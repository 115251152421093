import React from 'react';

const FewWavesManyPeaks = ({ reverse, bgColor, waveColor }) => {
    return (
        <svg
            width="100%"
            height="300px"
            viewBox="0 0 1920 1080"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={reverse && 'scale(1 -1)'}
        >
            <rect
                width="1920"
                height="1080"
                fill={bgColor}
            />
            <path
                d="M 0,1080 C 0,1080 0,180 0,180 C 43.243170799670935,186.13363605263095 86.48634159934187,192.26727210526187 130,198 C 173.51365840065813,203.73272789473813 217.29780440230348,209.06454763158342 258,207 C 298.7021955976965,204.93545236841658 336.32244079144425,195.47453736840444 377,202 C 417.67755920855575,208.52546263159556 461.41243243191946,231.0373028947988 498,237 C 534.5875675680805,242.9626971052012 564.0278294808779,232.37625105240042 601,219 C 637.9721705191221,205.62374894759958 682.4762496445691,189.45769289559945 720,177 C 757.5237503554309,164.54230710440055 788.0671719408459,155.79297736520175 822,149 C 855.9328280591541,142.20702263479825 893.2550625920471,137.3703976435935 940,127 C 986.7449374079529,116.6296023564065 1042.9125776909655,100.72543206042417 1091,123 C 1139.0874223090345,145.27456793957583 1179.0946266440906,205.7278741147098 1218,208 C 1256.9053733559094,210.2721258852902 1294.7089157326716,154.36307148073655 1325,151 C 1355.2910842673284,147.63692851926345 1378.0697104252235,196.81983996234408 1421,219 C 1463.9302895747765,241.18016003765592 1527.0122425664351,236.35756866988717 1574,229 C 1620.9877574335649,221.64243133011283 1651.8813193090366,211.74988535810732 1690,215 C 1728.1186806909634,218.25011464189268 1773.462480197418,234.64288989768363 1813,231 C 1852.537519802582,227.35711010231637 1886.268759901291,203.67855505115818 1920,180 C 1920,180 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.1"
            />
            <path
                d="M 0,1080 C 0,1080 0,360 0,360 C 32.44972165441537,354.1215341030183 64.89944330883074,348.2430682060366 106,359 C 147.10055669116926,369.7569317939634 196.85194841909242,397.149261278872 245,396 C 293.1480515809076,394.850738721128 339.69276301479965,365.1598866784751 381,352 C 422.30723698520035,338.8401133215249 458.3769995217091,342.21119200722745 494,333 C 529.6230004782909,323.78880799277255 564.799238898364,301.9953452926149 597,312 C 629.200761101636,322.0046547073851 658.4260448848348,363.807426822313 699,359 C 739.5739551151652,354.192573177687 791.4965815622966,302.7749474181331 841,294 C 890.5034184377034,285.2250525818669 937.5876288659794,319.0927835051546 979,345 C 1020.4123711340206,370.9072164948454 1056.152902973786,388.8539185612484 1096,384 C 1135.847097026214,379.1460814387516 1179.8007592388765,351.4915422498519 1219,338 C 1258.1992407611235,324.5084577501481 1292.644060070708,325.1799124393439 1334,344 C 1375.355939929292,362.8200875606561 1423.6230004782908,399.78880799277255 1459,392 C 1494.3769995217092,384.21119200722745 1516.8639380161283,331.6648555895661 1547,329 C 1577.1360619838717,326.3351444104339 1614.9212474571964,373.55176964896293 1661,390 C 1707.0787525428036,406.44823035103707 1761.4510721550869,392.12806581458204 1806,382 C 1850.5489278449131,371.87193418541796 1885.2744639224566,365.935967092709 1920,360 C 1920,360 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.2"
            />
            <path
                d="M 0,1080 C 0,1080 0,540 0,540 C 36.43574183968482,518.601495056291 72.87148367936965,497.2029901125819 116,507 C 159.12851632063035,516.7970098874181 208.94980712220632,557.7895346059634 243,565 C 277.0501928777937,572.2104653940366 295.32928783180506,545.6388714635647 339,548 C 382.67071216819494,550.3611285364353 451.7330415505735,581.6549795397777 502,575 C 552.2669584494265,568.3450204602223 583.7385459659007,523.741210377324 615,497 C 646.2614540340993,470.25878962267603 677.3127745858236,461.38017895092656 713,478 C 748.6872254141764,494.61982104907344 789.0103556908049,536.7380738189695 831,557 C 872.9896443091951,577.2619261810305 916.6458026509572,575.6675257731958 954,569 C 991.3541973490428,562.3324742268042 1022.406433705366,550.5918230882471 1068,535 C 1113.593566294634,519.4081769117529 1173.7284625275784,499.9651818738156 1215,508 C 1256.2715374724216,516.0348181261844 1278.6797161843203,551.5474494164905 1313,553 C 1347.3202838156797,554.4525505835095 1393.5526727351407,521.8450204602221 1437,519 C 1480.4473272648593,516.1549795397779 1521.109592875117,543.0724687426209 1566,535 C 1610.890407124883,526.9275312573791 1660.0089557643917,483.86510456929426 1697,479 C 1733.9910442356083,474.13489543070574 1758.8545840673166,507.4671129802017 1794,524 C 1829.1454159326834,540.5328870197983 1874.5727079663416,540.2664435098991 1920,540 C 1920,540 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.4"
            />
            <path
                d="M 0,1080 C 0,1080 0,720 0,720 C 45.32807880400348,725.1293570865439 90.65615760800696,730.2587141730877 132,750 C 173.34384239199304,769.7412858269123 210.70344837197553,804.0945003941928 249,786 C 287.2965516280245,767.9054996058072 326.5300489040908,697.3632842501412 360,692 C 393.4699510959092,686.6367157498588 421.17635601166114,746.452362605243 463,750 C 504.82364398833886,753.547637394757 560.7645270492644,700.827265328887 600,701 C 639.2354729507356,701.172734671113 661.7655357912811,754.2385760792089 705,755 C 748.2344642087189,755.7614239207911 812.1733297856111,704.2184303542773 858,687 C 903.8266702143889,669.7815696457227 931.5411450662739,686.8877025036819 962,714 C 992.4588549337261,741.1122974963181 1025.6620899492932,778.2307596309952 1061,786 C 1096.3379100507068,793.7692403690048 1133.8104951365538,772.1892589723375 1183,761 C 1232.1895048634462,749.8107410276625 1293.095929504491,749.012204479655 1341,753 C 1388.904070495509,756.987795520345 1423.8057868454819,765.7619231090426 1456,747 C 1488.1942131545181,728.2380768909574 1517.6809231135821,681.9401030841741 1553,679 C 1588.3190768864179,676.0598969158259 1629.4705207001894,716.4776645542607 1667,734 C 1704.5294792998106,751.5223354457393 1738.4369940856602,746.1492386987826 1780,740 C 1821.5630059143398,733.8507613012174 1870.7815029571698,726.9253806506088 1920,720 C 1920,720 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.6"
            />
            <path
                d="M 0,1080 C 0,1080 0,900 0,900 C 33.63439301393164,923.5160265217826 67.26878602786329,947.0320530435653 113,942 C 158.73121397213671,936.9679469564347 216.55924890247854,903.3878143475217 257,880 C 297.44075109752146,856.6121856524783 320.4942183622227,843.4166895663474 351,871 C 381.5057816377773,898.5833104336526 419.4638776486308,966.9454273870891 460,960 C 500.5361223513692,953.0545726129109 543.6502710432542,870.8016008852961 585,861 C 626.3497289567458,851.1983991147039 665.9350381783526,913.8481690717265 714,940 C 762.0649618216474,966.1518309282735 818.6095762433354,955.8057228277975 858,937 C 897.3904237566646,918.1942771722025 919.6266568483062,890.9289396170839 960,875 C 1000.3733431516938,859.0710603829161 1058.8837963634394,854.4785187038669 1097,876 C 1135.1162036365606,897.5214812961331 1152.838157697936,945.1569855674485 1191,949 C 1229.161842302064,952.8430144325515 1287.7635728448165,912.8935390263388 1327,888 C 1366.2364271551835,863.1064609736612 1386.1075509227976,853.2688583271965 1428,861 C 1469.8924490772024,868.7311416728035 1533.8062234639924,894.0310276648752 1575,917 C 1616.1937765360076,939.9689723351248 1634.667555221233,960.6070310133027 1673,957 C 1711.332444778767,953.3929689866973 1769.5235556510763,925.5408482819136 1814,912 C 1858.4764443489237,898.4591517180864 1889.238222174462,899.2295758590433 1920,900 C 1920,900 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="1"
            />
        </svg>
    );
};

export default FewWavesManyPeaks;
