import { useEffect, useState } from 'react';
import LocationPhoto1 from '../../assets/images/locations/lodz/Ogrodowa8_Neon1.png';
import LocationPhoto2 from '../../assets/images/locations/lodz/Ogrodowa8_Neon2.png';
import './LocationsCarousel.css';

const LocationsCarousel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const locationImages = [LocationPhoto1, LocationPhoto2];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(
                (prevIndex) => (prevIndex + 1) % locationImages.length
            );
        }, 3000);

        return () => clearInterval(interval);
    }, [locationImages.length]);

    return (
        <>
            <div className="">
                <div
                    className={`relative w-full  h-[calc(100vh-64px)] overflow-hidden select-none pointer-events-none 
                        bg-no-repeat bg-cover xl:max-h-screen img-height`}
                    style={{
                        backgroundImage: `url(${locationImages[currentImageIndex]})`,
                        transition: 'background-image 1s ease-in-out',
                    }}
                ></div>
            </div>
        </>
    );
};

export default LocationsCarousel;
