import React from 'react';

const Card = ({ imgSrc, title, subTitle, description }) => {
    return (
        <div className="px-4 sm:w-96">
            <div
                className="text-center shadow-md h-full border border-gray-200 bg-white border-opacity-60 rounded-lg overflow-hidden
                            transition-shadow duration-300 ease-in-out hover:shadow-xl hover:shadow-black/30"
            >
                <img
                    className="w-full h-64 object-cover object-center"
                    src={imgSrc}
                    alt={title}
                />
                <div className="p-6">
                    <h2 className="text-2xl font-bold mb-2">{title}</h2>
                    <h3 className="text-xs mb-3">{subTitle}</h3>
                    <p className="leading-relaxed mb-3">{description}</p>
                </div>
            </div>
        </div>
    );
};

const PartnerInfoCards = ({ data }) => {
    return (
        <div className="w-full flex justify-center content-center flex-wrap gap-6">
            {data.map((item, index) => (
                <PartnerInfoCard
                    partnerInfo={item}
                    key={index}
                />
            ))}
        </div>
    );
};

export const PartnerInfoCard = ({ partnerInfo }) => {
    return (
        <Card
            imgSrc={partnerInfo.imgSrc}
            title={partnerInfo.title}
            description={partnerInfo.description}
        />
    );
};

export default PartnerInfoCards;
