// CustomVideo.js
import React, { useRef, useEffect } from 'react';

const CustomVideo = ({src, textReplayButton }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleCanPlay = () => {
      if (videoRef.current) {
        videoRef.current.style.opacity = 1;
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
      return () => {
        videoElement.removeEventListener('canplay', handleCanPlay);
      };
    }
  }, []);

  return (
    <div className="video-container" style={{ width: '100%', height: '100%' }}>
      <video
        ref={videoRef}
        src={src}
        autoPlay
        muted
        preload="auto"
        playsInline
        style={{ width: '100%', height: '100%', opacity: 0, transition: 'opacity 0.2s ease', backgroundColor: '#002E3C' }}
      >
      </video>
      <button>{textReplayButton}</button>
    </div>
  );
};

export default CustomVideo;
