import React from 'react';

import Challenges from '../components/challenges/Challenges';

const ChallengesPage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <Challenges />
        </div>
    );
};

export default ChallengesPage;
