import React from 'react';

const ParagraphWrapper = ({ children, dashedBorderColor }) => {
    return (
        <div className="relative mx-0 md:mx-12 mb-12 z-10">
            <div className="bg-[#fff5bf] z-10 p-6 md:p-8">
                {/* <Paragraph className="">{children}</Paragraph> */}
                <div className="text-sm sm:text-lg tracking-normal text-primary2 ">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ParagraphWrapper;
