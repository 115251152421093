import React, { useContext, useEffect } from 'react';

import Challenges from '../components/landing-page-2023/challenges/Challenges-copy';
import PartnersSimple from '../components/landing-page-2023/partners/PartnersSimple-copy';
import ImpressionCards from '../components/landing-page-2023/impression-cards/ImpressionCards-copy';
import VideoStage from '../components/landing-page-2023/video-stage/VideoStage-copy';
import About from '../components/landing-page-2023/about/About-copy';
import Prizes from '../components/landing-page-2023/prizes/Prizes-copy';
import RegistrationInfo from '../components/landing-page-2023/registration-info/RegistrationInfo-copy';



import ConsentContext from '../context/consentContext';
import DesktopHero from '../components/landing-page-2023/hero/DesktopHero';
import MobileHero from '../components/landing-page-2023/hero/MobileHero';

const LandingPage2023 = () => {
    
    const { analyticsConsent } = useContext(ConsentContext);
    const {openModal, setOpenModal} = useContext(ConsentContext)
    console.log("Before...Local Storage: " + localStorage.getItem("analyticsConsent"))
    useEffect(() => {
        if (!localStorage.getItem("analyticConsent")){
            setOpenModal(true)
        }
        // if analytics consent is given, console log the value of the consent
        if (localStorage.getItem("analyticConsent") === "true") {
            console.log("After...Local Storage: " + localStorage.getItem("analyticsConsent"))
            // Create the Adform tracking script element
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://a2.adform.net/banners/scripts/st/trackpoint-async.js';
        
            // Append the script to the document's head
            const x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(script, x);
        
            // Define the tracking configuration
            window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : [];
            window._adftrack.push({
            HttpHost: 'track.adform.net',
            pm: 2354148,
            divider: encodeURIComponent('|'),
            pagename: encodeURIComponent('Hackathon_LP')
            });
        
            // Create the noscript element for tracking
            const noscript = document.createElement('noscript');
            noscript.innerHTML = `
            <p style="margin:0;padding:0;border:0;">
                <img src="https://track.adform.net/Serving/TrackPoint/?pm=2354148&ADFPageName=Hackathon_LP&ADFdivider=|" width="1" height="1" alt="" />
            </p>
            `;
        
            // Append the noscript element to the document's body
            document.body.appendChild(noscript);
        }
    }, [analyticsConsent, openModal, setOpenModal]);

      
    
    return (
        <div className="bg-white">
            <div className="hidden xl:block">
                <DesktopHero />
            </div>
            <div className="block xl:hidden">
                <MobileHero />
            </div>
            <About />
            <Prizes />
            <Challenges />
            <PartnersSimple />
            <ImpressionCards />
            <VideoStage />
            <RegistrationInfo />
        </div>
    );
};

export default LandingPage2023;
