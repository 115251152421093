import { Button, Icon } from '@lsg/components';
import { interaction_arrows_arrowRight } from '@lsg/icons';

const AboutTheLocation = () => {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div className="py-12 md:py-20 bg-primary2">
                <h1 className="text-4xl xs:text-4xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl font-bold uppercase hyphens-none m-auto w-8/12 text-left">
                    <span className="text-white">About </span>
                    <span className="text-[#E6007E] font-extrabold">
                        the Location
                    </span>
                </h1>
                <p className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white m-auto w-8/12 text-left">
                Collabothon will welcome you in the modern office building in very heart of Łódź, Ogrodowa 8 Office. The venue is designed to be a hub for creative minds to gather and work together on their ideas. The Ogrodowa Office building is perfectly connected with every part of the city. Next to the building there are numerous bus and tram stops and city bike stations.  Convenient and fast transport with other Polish cities will be ensured by the location in close proximity to the Łódź Fabryczna, Kaliska and Żabieniec railway stations, as well as motorways and expressways.
                </p>
                <div className="flex flex-wrap justify-center items-center text-primary2 mt-10">
                    <Button
                        className="p-1 cursor-pointer flex w-2/5 md:w-fit"
                        onClick={() =>
                            openInNewTab(
                                'https://maps.app.goo.gl/rotKyjxyX3aV1MxM6'
                            )
                        }
                        label={'Loading Button'}
                    >
                        <span className="font-medium text-xs xs:text-sm md:text-lg ">
                            How to get here?
                        </span>
                        <Icon
                            icon={interaction_arrows_arrowRight}
                            className="ml-3 inline-block mt-3.5"
                        />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AboutTheLocation;
