import React from 'react';
import impression1 from '../../../assets/images/gallery/edition_2022/photo_1.jpg';
import impression2 from '../../../assets/images/gallery/edition_2022/photo_3.jpg';
import impression3 from '../../../assets/images/gallery/edition_2022/photo_4.jpg';

const About = () => {
    return (
        <div
            className="max-w-[1920px] flex mx-auto bg-white"
            id="about-section"
        >
            <div className="">
                {/* flex-wrap md:flex-nowrap */}
                <div className="flex flex-row flex-wrap md:flex-nowrap">
                    <div className="p-8 xs:p-16 md:w-1/2">
                        <h1 className="text-2xl xs:text-3xl md:text-xl lg:text-2xl xl:text-4xl 2xl:text-6xl 3xl:text-7xl font-extrabold uppercase hyphens-none">
                            Ignite Innovation at{' '}
                            <span className=" text-cobaLight600">
                                Collabothon
                            </span>
                        </h1>
                        <p className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none">
                            Join us at Collabothon, where we bring together a
                            great team of collaborators to tackle some of the
                            most pressing challenges of our time. Collaborate
                            with fellow innovators from around Europe to develop
                            cutting-edge solutions. And here's the best part -
                            you can win substantial cash prizes that'll make
                            your efforts even more rewarding!
                        </p>
                    </div>
                    <div className="px-8 xs:px-16 md:px-0 md:w-1/2">
                        <img
                            src={impression1}
                            alt="impressions"
                            className="md:h-full object-cover"
                        ></img>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap md:flex-nowrap">
                    <div className="px-8 xs:px-16 md:px-0 md:w-1/2 order-2 md:order-1">
                        <img
                            src={impression2}
                            alt="impressions"
                            className="md:h-full object-cover"
                        ></img>
                    </div>
                    <div className="p-8 xs:p-16 md:w-1/2 order-1 md:order-2">
                        <h1 className="text-2xl xs:text-3xl md:text-xl lg:text-2xl xl:text-4xl 2xl:text-6xl 3xl:text-7xl font-extrabold uppercase hyphens-none">
                            Enhance Your{' '}
                            <span className=" text-secondary4">Skills</span> and
                            Expand Your{' '}
                            <span className=" text-secondary4">Network</span>
                        </h1>
                        <p className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none">
                            Collabothon is the perfect place to develop your
                            skills and grow your network. Work alongside
                            industry experts, mentors, and like-minded
                            individuals as you tackle real-world challenges. Our
                            immersive event offers the perfect opportunity to
                            learn, collaborate, and build lasting connections.
                        </p>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap md:flex-nowrap">
                    <div className="p-8 xs:p-16 md:w-1/2">
                        <h1 className="text-2xl xs:text-3xl md:text-xl lg:text-2xl xl:text-4xl 2xl:text-6xl 3xl:text-7xl font-extrabold uppercase hyphens-none">
                            Create Impact{' '}
                            <span className=" text-cobaLight600">
                                Together With Your Team
                            </span>
                        </h1>
                        <p className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none">
                            At Collabothon, we belive in the power of teamwork
                            to drive innovation and make a real difference in
                            the world. Bring your team of 3 to 6 innovators
                            together and join our event from the 20th October to
                            the 22nd October to innovate solutions that have the
                            potential to create real impact.
                        </p>
                    </div>
                    <div className="px-8 pb-8 xs:px-16 xs:pb-16 md:px-0 md:pb-0 md:w-1/2">
                        <img
                            src={impression3}
                            alt="impressions"
                            className="md:h-full object-cover"
                        ></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
