import React from 'react';
import './LoadingScreen.css'; 
import loadingGif from '../../../assets/videos/mewa_mp4.mp4';
import CustomVideo from './CustomVideo';

const LoadingScreen = () => {
  return (
    <div className="loading-screen max-w-[30000px] mx-auto ">
      <CustomVideo
        videoTitle="Loading"
        src={loadingGif}
        textReplayButton="Replay"
      />
    </div>
  );
};

export default LoadingScreen;