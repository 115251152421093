import React from 'react';

const FewWavesLessPeaks = ({ reverse, bgColor, waveColor }) => {
    return (
        <svg
            width="100%"
            height="100px"
            viewBox="0 0 1920 1080"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={reverse && 'scale(1 -1)'}
        >
            <rect
                width="1920"
                height="1080"
                fill={bgColor}
            />
            <path
                d="M 0,1080 C 0,1080 0,180 0,180 C 156.71770334928226,158.86124401913875 313.4354066985645,137.7224880382775 443,128 C 572.5645933014355,118.27751196172248 674.976076555024,119.97129186602871 790,137 C 905.023923444976,154.0287081339713 1032.6602870813397,186.39234449760767 1165,179 C 1297.3397129186603,171.60765550239233 1434.3827751196172,124.45933014354065 1561,118 C 1687.6172248803828,111.54066985645935 1803.8086124401914,145.77033492822966 1920,180 C 1920,180 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.15"
            />
            <path
                d="M 0,1080 C 0,1080 0,360 0,360 C 155.78947368421052,355.799043062201 311.57894736842104,351.59808612440196 443,341 C 574.421052631579,330.40191387559804 681.4736842105261,313.4066985645933 778,330 C 874.5263157894739,346.5933014354067 960.5263157894738,396.7751196172249 1095,400 C 1229.4736842105262,403.2248803827751 1412.421052631579,359.49282296650716 1558,345 C 1703.578947368421,330.50717703349284 1811.7894736842104,345.2535885167464 1920,360 C 1920,360 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.4"
            />
            <path
                d="M 0,1080 C 0,1080 0,540 0,540 C 162.22966507177034,543.4162679425838 324.4593301435407,546.8325358851674 443,566 C 561.5406698564593,585.1674641148326 636.3923444976076,620.0861244019138 762,603 C 887.6076555023924,585.9138755980862 1063.9712918660289,516.8229665071771 1187,508 C 1310.0287081339711,499.17703349282294 1379.7224880382776,550.6220095693781 1493,566 C 1606.2775119617224,581.3779904306219 1763.1387559808613,560.688995215311 1920,540 C 1920,540 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="0.7"
            />
            <path
                d="M 0,1080 C 0,1080 0,720 0,720 C 121.35885167464119,741.866028708134 242.71770334928237,763.7320574162679 387,775 C 531.2822966507176,786.2679425837321 698.4880382775119,786.9377990430622 837,768 C 975.5119617224881,749.0622009569378 1085.33014354067,710.5167464114833 1203,708 C 1320.66985645933,705.4832535885167 1446.1913875598084,738.9952153110048 1567,747 C 1687.8086124401916,755.0047846889952 1803.9043062200958,737.5023923444976 1920,720 C 1920,720 1920,1080 1920,1080 Z"
                fill={waveColor}
                opacity="1"
            />
        </svg>
    );
};

export default FewWavesLessPeaks;
