import React from 'react';

import Headline from '../../common/Headline';
import OrderOfEvents from '../order-of-events/OrderOfEvents-copy';
import { eventsData } from '../order-of-events/orderOfEventsData-copy';
import RegisterBanner from '../../../assets/images/collabothon_register_banner2.png';

const RegistrationInfo = () => {
    return (
        <div className="bg-white">
            <Headline
                headline="Join this years edition!"
                classNames="text-primary2 pt-20"
            />
            <div className="w-full px-6 xl:px-0 xl:w-3/4 3xl:w-1/2 5xl:w-1/3 mx-auto pb-20">
                <OrderOfEvents data={eventsData} />

                <div className="relative my-12">
                    <div className="flex justify-center items-center sm:px-12">
                        <div className="relative">
                            <img
                                className="w-full object-cover h-[512px] object-center rounded-xl"
                                src={RegisterBanner}
                                alt={'Address of the Venue'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationInfo;
