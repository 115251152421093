import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// import Nav from './Components/Layout/Nav/Nav';
import ChallengesPage from './pages/ChallengesPage';
import RulesPage from './pages/RulesPage';
import LandingPage from './pages/LandingPage';
import LandingPage2023 from './pages/LandingPage2023';
import LocationsPage from './pages/LocationsPage';
import PartnersPage from './pages/PartnersPage';
import FaqPage from './pages/FaqPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import LegalDisclosurePage from './pages/LegalDisclosurePage';
import GalleryPage from './pages/GalleryPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import FourOhFour from './pages/FourOhFour';
import Layout from './components/layout/Layout';

import './App.css';
import { useState } from 'react';
import ConsentBanner from './components/consent-banner/ConsentBanner';
import ConsentContext from './context/consentContext';
import ScrollToTop from './components/common/ScrollToTop';
import Schedule from './pages/Schedule';

function App() {
    const [analyticsConsent, setAnalyticsConsent] = useState(false);
    const [userResponded, setUserResponded] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const value = {
        analyticsConsent,
        setAnalyticsConsent,
        userResponded,
        setUserResponded,
        openModal,
        setOpenModal,
    };

    return (
        <ConsentContext.Provider value={value}>
            <BrowserRouter>
                <ConsentBanner />
                <ScrollToTop />
                <Layout>
                    <Routes>
                        <Route
                            exact
                            path={'/'}
                            element={
                                <Navigate
                                    to="/home"
                                    replace
                                ></Navigate>
                            }
                        />
                        <Route
                            exact
                            path={'/home'}
                            element={<LandingPage />}
                        />
                        <Route
                            exact
                            path={'/challenges'}
                            element={<ChallengesPage />}
                        />
                        <Route
                            exact
                            path={'challenges/:challengeId'}
                            element={<ChallengesPage />}
                        />
                        <Route
                            exact
                            path={'/locations'}
                            element={<LocationsPage />}
                        />
                        <Route
                            exact
                            path={'/locations/:locationId'}
                            element={<LocationsPage />}
                        />
                        <Route
                            exact
                            path={'/rules'}
                            element={<RulesPage />}
                        />
                        <Route
                            exact
                            path={'/partners'}
                            element={<PartnersPage />}
                        />
                        <Route
                            exact
                            path={'/faq'}
                            element={<FaqPage />}
                        />
                        <Route
                            exact
                            path={'/gallery'}
                            element={<GalleryPage />}
                        />
                        <Route
                            exact
                            path={'/terms-and-conditions'}
                            element={<TermsAndConditionsPage />}
                        />
                        <Route
                            exact
                            path={'/privacy-policy'}
                            element={<PrivacyPolicyPage />}
                        />
                        <Route
                            exact
                            path={'/imprint'}
                            element={<LegalDisclosurePage />}
                        />
                        <Route
                            exact
                            path={'/2023'}
                            element={<LandingPage2023 />}
                        />
                        <Route
                            exact
                            path={'*'}
                            element={<FourOhFour />}
                        />
                        <Route
                            exact
                            path={'/Schedule'}
                            element={<Schedule />}
                        />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </ConsentContext.Provider>
    );
}

export default App;
