import React from 'react';
import { useLocation } from 'react-router-dom';

// import Nav from './Nav/Nav';
import CustomHeader from './CustomHeader';
import CustomFooter from './CustomFooter';
import { Button } from '@lsg/components';
import { useState } from 'react';
import DiscordModal from './DiscordModal';

const Layout = (props) => {
    const location = useLocation();
    const pathName = location.pathname;
    const [openModal, setOpenModal] = useState(false);
    return (
        <div className="flex flex-col min-h-screen">
            <header>
                <CustomHeader />
            </header>
            <main className="flex-grow">
                <div
                    className={`${
                        pathName !== '/' &&
                        pathName !== '/home' && 
                        pathName !== '/2023' &&
                        !pathName.startsWith('/locations') &&
                        pathName !== '/partners' &&
                        pathName !== '/Schedule' &&
                        pathName !== '/loading-page' &&
                        'container mx-auto py-12 xs:py-24 px-4 md:px-8 2xl:px-0'
                    }`}
                >
                    {props.children}
                </div>
            </main>

            {!openModal && (
                <Button
                    className=" fixed bottom-4 sm:bottom-16 right-6 sm:right-16 w-[150px] z-50"
                    onClick={() => setOpenModal(true)}
                >
                    Join our Discord
                </Button>
            )}
            <div className=" flex fixed bottom-8 right-6 z-[60]">
                {openModal && (
                    <DiscordModal onClose={() => setOpenModal(false)} />
                )}
            </div>
            <footer className="z-[90]">
                <CustomFooter />
            </footer>
        </div>
    );
};

export default Layout;
