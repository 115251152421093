import React from 'react';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import cloud1 from '../../../assets/images/hero/Group 29cloud_.png';
import cloud2 from '../../../assets/images/hero/Group 28cloud_.png';
import cloud3 from '../../../assets/images/hero/Group 27cloud_.png';
import cloud4 from '../../../assets/images/hero/Group 26cloud_.png';
import cloud5 from '../../../assets/images/hero/Group 25cloud_.png';
import cloud6 from '../../../assets/images/hero/Group 24cloud_.png';
import cloud7 from '../../../assets/images/hero/Group 23cloud_.png';
import cloud8 from '../../../assets/images/hero/Group 22cloud_.png';
import cloud9 from '../../../assets/images/hero/Group 21cloud_.png';
import cloud10 from '../../../assets/images/hero/Group 20cloud_.png';
import cloud11 from '../../../assets/images/hero/Group 19cloud_.png';
import cloud12 from '../../../assets/images/hero/Group 18cloud_.png';
import cloud13 from '../../../assets/images/hero/Group 17cloud_.png';
import cloud14 from '../../../assets/images/hero/Group 16cloud_.png';
import cloud15 from '../../../assets/images/hero/Group 15cloud_.png';
import cloud16 from '../../../assets/images/hero/Group 14cloud_.png';
import cloud17 from '../../../assets/images/hero/Group 13cloud_.png';

import collabothonLogo from '../../../assets/images/logo/logo_inverted_color.svg';
import collabothonLogoTriangle from '../../../assets/images/logo/logo_only_triangle.svg';

import AnimatedDiv from '../animation/AnimatedDiv';
import CountdownBanner from './CountdownBanner';
import RegistrationButton from '../registration-info/RegistrationButton-copy';

const DesktopHero = () => {
    const { height } = useWindowDimensions();

    const navbarHeightDesktop = 88;
    const heightReduceFactor = 1.4; // higher value = clouds will be lower in the end

    return (
        // dark bg - before animation: bg-[#0a4852]
        // light bg - after animation:  bg-[#01687a]
        // idea: show a big desktop version starting from breaking point "xl" and show a mobile version for everything below
        <>
            <motion.div
                animate={{
                    backgroundColor: ['#0a4852', '#01687a'],
                }}
                transition={{ ease: 'easeOut', duration: 2 }}
                className="h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden select-none pointer-events-none"
            >
                <div className="relative h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden select-none pointer-events-none">
                    {/* Collabothon Logo */}
                    <AnimatedDiv
                        child={
                            <img
                                src={collabothonLogo}
                                alt="collabothon logo"
                                className="absolute w-[800px] left-[calc(50%-400px)] xl:w-[1000px] xl:left-[calc(50%-500px)] 2xl:w-[1200px] 2xl:left-[calc(50%-600px)] select-none pointer-events-none"
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y: height * 0.1,
                                transition: { delay: 0.2, duration: 2 },
                                scale: 0.8,
                            },
                            hidden: { opacity: 0, y: height * 0.5 },
                        }}
                        parentClasses={'z-[25] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        child={
                            <img
                                src={collabothonLogoTriangle}
                                alt="collabothon logo"
                                className="absolute w-[800px] left-[calc(50%-400px)] xl:w-[1000px] xl:left-[calc(50%-500px)] 2xl:w-[1200px] 2xl:left-[calc(50%-600px)] select-none pointer-events-none"
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y: height * 0.1,
                                scale: 0.8,
                                transition: { delay: 0.2, duration: 2 },
                            },
                            hidden: { opacity: 1, y: height * 0.5, scale: 1 },
                        }}
                        parentClasses={'z-[25] relative'}
                        isBidirectional={false}
                    />
                    {/* Sub Title */}
                    <AnimatedDiv
                        child={
                            <div
                                className={
                                    'absolute text-center w-[1000px] left-[calc(50%-500px)] pointer-events-auto'
                                }
                            >
                                <div className="text-4xl 2xl:text-5xl text-white text-center font-extrabold hyphens-none">
                                    SKY IS THE LIMIT?
                                </div>
                                <div className="mt-8 text-3xl 2xl:text-4xl text-white text-center font-extralight hyphens-none">
                                    The international tech hackathon <br />
                                    from October 20th to 22nd 2023!
                                </div>
                                <div className="my-4">
                                    <div className="flex md:hidden justify-center items-center">
                                        <RegistrationButton
                                            small
                                            buttonLabel={'Register now'}
                                        />
                                    </div>
                                    <div className="hidden md:flex justify-center items-center">
                                        <RegistrationButton
                                            buttonLabel={'Register now'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y: isMobile ? height * 0.225 : height * 0.225,
                                scale: 1,
                                transition: { delay: 1.5, duration: 0.7 },
                            },
                            hidden: {
                                opacity: 0,
                                y: isMobile ? height * 0.225 : height * 0.3,
                                scale: 1.05,
                            },
                        }}
                        parentClasses={'z-[27] relative'}
                        isBidirectional={false}
                    />
                    {/* Cloud Images */}
                    <>
                        <AnimatedDiv
                            // LMC Asset 3
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[26%]
                                    src={cloud1}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.32 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.26,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 1 - mitte links spitz cloud
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[34%]
                                    src={cloud2}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.5 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.3,
                                },
                            }}
                            parentClasses={'z-[19] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 8
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[42%]
                                    src={cloud3}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.48 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.42,
                                },
                            }}
                            parentClasses={'z-[10] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 11 - middle cloud bottom - the important one
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[36%]
                                    src={cloud4}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.4 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.34,
                                },
                            }}
                            parentClasses={'z-[26] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 5 - left cloud
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[21%]
                                    src={cloud5}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.55 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.21,
                                },
                            }}
                            parentClasses={'z-[10] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 1 1
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[42%]
                                    src={cloud6}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.42 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.42,
                                },
                            }}
                            parentClasses={'z-[10] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 2 1
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[38%]
                                    src={cloud7}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.38 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.38,
                                },
                            }}
                            parentClasses={'z-[10] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 8 - middle cloud
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[23%]
                                    src={cloud8}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.3 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.23,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 7
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[18%]
                                    src={cloud9}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.35 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.18,
                                },
                            }}
                            parentClasses={'z-[15] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 1
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[8%]
                                    src={cloud10}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.3 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.08,
                                },
                            }}
                            parentClasses={'z-[15] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 5
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[17%]
                                    src={cloud11}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.28 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.17,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 9 - far left
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[32%]
                                    src={cloud12}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.35 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.32,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 4
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[14%]
                                    src={cloud13}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.4 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.14,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 7
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[14%]
                                    src={cloud14}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.45 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.14,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 10
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[4%]
                                    src={cloud15}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.25 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.04,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 12
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[8%]
                                    src={cloud16}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.32 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: (height - navbarHeightDesktop) * 0.08,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                        <AnimatedDiv
                            // LMC Asset 12
                            child={
                                <img
                                    className="z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0" // top-[0%]
                                    src={cloud17}
                                    alt={'cloud'}
                                />
                            }
                            parentVariant={{
                                visible: {
                                    opacity: 1,
                                    y:
                                        (height - navbarHeightDesktop) *
                                        0.325 *
                                        heightReduceFactor,
                                    transition: { duration: 2 },
                                },
                                hidden: {
                                    opacity: 1,
                                    y: 0,
                                },
                            }}
                            parentClasses={'z-[20] relative'}
                            isBidirectional={false}
                        />
                    </>

                    {/* Info banner */}
                    <AnimatedDiv
                        child={<CountdownBanner />}
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                transition: { delay: 1.5, duration: 1 },
                            },
                            hidden: {
                                opacity: 0,
                            },
                        }}
                        parentClasses={'z-[26] bannerOverlay'}
                        isBidirectional={false}
                    />
                </div>
            </motion.div>
        </>
    );
};

export default DesktopHero;
