import React, { useState } from 'react';
import { SimpleHeader} from '@lsg/components';
import { useLocation, useNavigate } from 'react-router-dom';

import './CustomHeader.css';
import { useEffect } from 'react';

const CustomHeader = () => {
    const location = useLocation().pathname.split('/')[1];
    let navigate = useNavigate();
    const [selectedNavItem, setSelectedNavItem] = useState(location);

    const navigationTree = [
        {
            label: 'Home',
            name: 'home',
        },
        {
            label: 'Challenge',
            name: 'challenges',
        },
        {
            label: 'Rules',
            name: 'rules',
        },
        {
            label: 'Schedule',
            name: 'Schedule',
        },
        {
            label: 'Location',
            name: 'locations',
        },
        {
            label: 'Edition 2023',
            name: '2023',
        },
    ];

    useEffect(() => {
        setSelectedNavItem(location);
    }, [location]);

    const handleNavigation = (value) => {
        setSelectedNavItem(value);
        navigate(value);
    };
    return (
        <SimpleHeader
            isSticky={true}
            logoHref="/home"
            logoSrcDesktop="/logo_inverted_color.svg"
            logoSrcMobile="/logo_inverted_color_mobile.svg"
            logoAlt="Commerzbank Logo"
            // segmentLabel="COLLABOTHON 2023"
            navigationTree={navigationTree}
            value={selectedNavItem}
            onChange={(value) => handleNavigation(value)}
        />
    );
};


export default CustomHeader;

