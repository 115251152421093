import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Font = styled.div`
    font-family: var(--lsg-font-family-400, 'Gotham', sans-serif);
`;

const OrderOfEvents = ({ data, classNames }) => {
    const [connectorHeight, setConnectorHeight] = useState(undefined);

    useEffect(() => {
        let divHeight;
        if (!isMobile) {
            divHeight = document.getElementById('numberContainer').clientHeight;
        } else {
            divHeight =
                document.getElementById('descriptionContainer').clientHeight +
                56;
        }
        setConnectorHeight(divHeight + 'px');
    }, []);

    return (
        <Font className={classNames}>
            {data.map((item, index) => (
                <div
                    key={index}
                    className="grid grid-cols-12"
                >
                    <div
                        id="numberContainer"
                        className={`col-span-2 md:col-span-1 flex justify-center`}
                    >
                        <div className="relative">
                            <div className="absolute w-[40px] h-[40px] -left-[18px] z-20 flex justify-center items-center rounded-full bg-[#FFD700] text-white font-bold text-lg">
                                {item.number}
                            </div>
                            {index < data.length - 1 && (
                                <div
                                    className={`absolute w-[4px] mt-4 z-10 bg-[#FFD700] top-0 rounded-full`}
                                    style={{
                                        height: connectorHeight,
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`col-span-9 col-start-3 flex items-center md:block md:mt-1.5 md:col-span-3 text-lg md:text-xl font-bold pt-1.5 pb-4 pl-2.5 md:pt-0 md:pb-0 md:pl-0`}
                    >
                        {item.title}
                    </div>
                    <div
                        id="descriptionContainer"
                        className={`col-span-10 col-start-3 md:col-span-8 md:col-start-0 mb-4 mr-6`}
                    >
                        <div
                            className={`grid-cols-1 rounded-2xl overflow-hidden shadow-md bg-[#002E3C] transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/50`}
                        >
                            <div
                                className={`text-[#DBE2E5] p-5 block hyphens-none`}
                            >
                                {item.description}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Font>
    );
};
export default OrderOfEvents;
