import React from 'react';
import { Button } from '@lsg/components';

import Headline from '../components/common/Headline';
import TermsAndConditionsPDF from '../assets/rules and regulations/Collabothon - Terms and Conditions.pdf';

const TermsAndConditionsPage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <Headline
                headline="Terms and Conditions"
                classNames="text-primary2"
            />
            <div className="mx-4 sm:mx-24">
                <p className="mb-6 sm:mb-12 hyphens-none">
                    Welcome to our event's Terms and Conditions page, where we
                    provide essential guidelines and information for
                    participants and attendees. This page encompasses the rules
                    and regulations governing your participation in our event.
                    We encourage you to read the terms carefully to understand
                    your rights and responsibilities throughout the event. If
                    you have any questions or require further clarification,
                    please don't hesitate to reach out to our support team by
                    writing us an email to <b>collabothon@commerzbank.com</b>.
                    Thank you for being part of our event, and we wish you a
                    fantastic and productive experience!
                </p>
                <div className="flex flex-wrap sm:space-x-8">
                    <Button
                        href={TermsAndConditionsPDF}
                        htmlAttrs={{ target: '_blank', rel: 'noreferrer' }}
                    >
                        Terms and Conditions
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsPage;
