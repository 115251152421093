import React from 'react';
import { Button } from '@lsg/components';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const FourOhFour = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="text-3xl font-bold w-full text-center my-24">
                <Typewriter
                    options={{
                        strings: ['404, page not found...'],
                        autoStart: true,
                        loop: true,
                        delay: 150,
                    }}
                />
                <div className="flex justify-center items-center my-24 mx-24">
                    <Button
                        actionAs={Link}
                        actionProps={{ to: '/home' }}
                    >
                        Go Back Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FourOhFour;
