import React from 'react';
// import { useNavigate } from 'react-router-dom';

import ChallengeSection from './ChallengeSection-copy';

const Challenges = () => {
    // let navigate = useNavigate();

    return (
        <div className="bg-primary2 md:pb-20">
            <div className="flex flex-row flex-wrap md:flex-nowrap justify-around max-w-[1920px] mx-auto hyphens-none">
                <ChallengeSection
                    companyName={'Commerzbank'}
                    teaser={
                        'Embark on a coding journey that transforms online banking into an adventure. Delve into a world of endless possibilities and create a feature that is pure innovation.'
                    }
                    onClick={() => {
                       // navigate('/challenges/commerzbankChallenge');
                    }}
                    bgColor={'bg-cobaLight500'}
                />
                <ChallengeSection
                    companyName={'Red Hat'}
                    teaser={
                        'Gather with your community of developers to Challenge the status quo and reshape traditional banking the open source way.'
                    }
                    onClick={() => {
                        // navigate('/challenges/redhatChallenge');
                    }}
                    bgColor={'bg-cobaLight400'}
                />
                <ChallengeSection
                    companyName={'Google'}
                    teaser={
                        "The future is calling for innovative ways to tackle global warming. Dive into our challenge and craft a solution that's not just inventive - it's a game-changer."
                    }
                    onClick={() => {
                       // navigate('/challenges/googleChallenge');
                    }}
                    bgColor={'bg-cobaLight300'}
                />
            </div>
        </div>
    );
};

export default Challenges;
