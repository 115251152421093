import React from 'react';

import { Icon } from '@lsg/components';
import { object_document_signature } from '@lsg/icons';
// windowUtils OpenInNewTab has additional function within for Google Analytics if consent was given by the user.

const RegistrationButton = ({ small, buttonLabel }) => {
    return (
        <div
            className={`flex justify-center items-center py-4 px-8
                        hover:cursor-pointer hover:bg-primary1Highlighted duration-200 ease-in-out
                        rounded-full bg-primary1 text-primary2 font-bold
                        ${small === true ? 'text-lg' : 'text-2xl'}`}
            onClick={() => {
            }}
        >
            <Icon
                icon={object_document_signature}
                variant="solid"
                size={'small'}
                inline={true}
                color="secondary"
            />
            <div className="ml-4">{buttonLabel}</div>
        </div>
    );
};

export default RegistrationButton;
