import React from 'react';

import AboutTheLocation from '../components/locations/AboutTheLocation';
import AvailableFacitilies from '../components/locations/AvailableFacitilies';
import LocationsHeader from '../components/locations/LocationsHeader';
import LocationsCarousel from '../components/locations/LocationsCarousel';

const LocationsPage = () => {
    
    return (
        <>
            <LocationsHeader />
            <AboutTheLocation />
            <LocationsCarousel />
            <AvailableFacitilies />
            

        </>
    );
};

export default LocationsPage;
