import React from 'react';

import RedHatLogo from '../../../assets/images/partners/redhat_logo.png';
import OreillyLogo from '../../../assets/images/partners/oreilly_logo.png';
import LodzLogo from '../../../assets/images/partners/lodz_logo_new.png';
import ICTLogo from '../../../assets/images/partners/ICT_logo.png';
import LodzTUL from '../../../assets/images/partners/Lodz_University_of_Technology.png';

const PartnersSimple = () => {
    return (
        <div className="pt-20 pb-16 mx-auto bg-white">
            <h1 className="text-center uppercase font-bold text-4xl md:text-5xl lg:text-6xl mb-4 sm:mb-12 w-full z-10 text-primary2">
                OUR{' '}
                <span className="text-[#FF018C] font-extrabold inline-block transform -skew-x-12">
                    PARTNERS
                </span>
            </h1>
            <div className="flex justify-center items-center flex-wrap gap-10 xs:gap-16 px-8 md:px-24 xl:px-48 p-24">
                <a href="https://www.redhat.com/en" target="_blank" rel="noreferrer noopener">
                    <img
                        src={RedHatLogo}
                        alt={`Red Hat Logo`}
                        className="h-10 xs:h-12 md:h-20 object-scale-down"
                    />
                </a>
                <a href="https://www.oreilly.com/" target="_blank" rel="noreferrer noopener">
                    <img
                        src={OreillyLogo}
                        alt={`{Oreilly Logo`}
                        className="h-12 xs:h-16 md:h-24 object-scale-down"
                    />
                </a>
                {/* <a href="https://ictcluster.pl/en/" target="_blank" rel="noreferrer noopener">
                    <img
                        src={ICTLogo}
                        alt={` Logo`}
                        className="h-12 xs:h-16 md:h-24 object-scale-down"
                    />
                </a> */}
                <a href="https://p.lodz.pl/en" target="_blank" rel="noreferrer noopener">
                    <img
                        src={LodzTUL}
                        alt={`Lodz University Of Technology`}
                        className="h-12 xs:h-16 md:h-24 object-scale-down ml-2 mr-2"
                    />
                </a>
                <a href="https://lodz.travel/en/tourism/" target="_blank" rel="noreferrer noopener">
                    <img
                        src={LodzLogo}
                        alt={`Placeholder Logo`}
                        className="h-16 xs:h-24 md:h-36 object-scale-down"
                    />
                </a>
            </div>
        </div>
    );
};

export default PartnersSimple;
