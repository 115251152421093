import React from 'react';

const About = () => {
    return (
        <div
            className="max-w-[1920px] w-8/12 md:w-10/12 flex mx-auto bg-white"
            id="about-section"
        >
            <div className="">
                <div className="flex flex-row flex-wrap gap-x-32 gap-y-12 md:flex-nowrap py-12 lg:py-24">
                    <div className="md:w-1/2">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl text-primary2 font-bold uppercase hyphens-none">
                            What is <br />
                            <span className="text-[#FF018C] font-black">
                                Collabothon
                            </span>
                        </h1>
                        <p className="mt-4 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-justify">
                            Collabothon is where creativity meets technology!
                            This 3-day hackathon unites minds from all
                            backgrounds, including students and professionals,
                            to tackle real-world problems. Form teams,
                            collaborate, and create cutting-edge solutions.
                            Whether you're into coding, designing, or
                            strategizing, Collabothon welcomes everyone. Join us
                            for a fun, inspiring event where you'll learn,
                            network, and make a real impact.
                        </p>
                    </div>
                    <div className="md:w-1/2">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl text-primary2 font-bold uppercase hyphens-none">
                            About <br />
                            <span className="text-[#FF018C] font-black">
                                2024 edition
                            </span>
                        </h1>
                        <p className="mt-4 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-justify">
                            Step into the 2024 Collabothon in Lodz, a city known
                            for its rich legacy of innovation. Inspired by
                            Lodz's journey from textile hub to tech center, this
                            year’s challenges are all about pioneering digital
                            solutions for Commerzbank. Get set to innovate and
                            develop groundbreaking prototypes with #Code4Bank.
                            Immerse yourself in the city’s creative energy and
                            play a role in shaping the future of banking. This
                            year’s event promises to be our most exciting yet!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
