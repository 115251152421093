import React from 'react';
import { Accordion, Paragraph, Button, IconLink, H4 } from '@lsg/components';
import { interaction___close } from '@lsg/icons';
import { isMobile } from 'react-device-detect';

const DiscordModal = ({ onClose }) => {
    return (
        <div className="flex flex-col p-10 border-2 rounded-lg max-w-[90vw] md:max-w-[600px] max-h-[90vh] bg-white/75 shadow-2xl backdrop-blur-xl overflow-x-scroll">
            <IconLink
                icon={interaction___close}
                onClick={onClose}
            >
                Close
            </IconLink>
            <div className=" flex flex-col  justify-center items-center content-center ">
                <H4 className="text-lg xl:text-2xl font-bold text-center">
                    Are you looking for a team?
                </H4>
                <p className="pb-10">
                    We are thrilled to have you here, eager and ready to
                    innovate! To enhance your Hackathon experience, we have set
                    up an exclusive Discord Server for all participants.
                    <br />
                    <br />
                    <span className="font-bold">
                        Why Join Our Discord Server?
                    </span>
                    <br />
                    <br />
                    {isMobile ? (
                        <Accordion.Group multiple={false}>
                            <Accordion
                                key={'teamForming'}
                                title={'Team Forming'}
                            >
                                <Paragraph>
                                    Looking for like-minded innovators or
                                    missing a key skill in your group? Join our
                                    Discord community to connect with other
                                    participants and form the ultimate team!
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                key={'collaboration'}
                                title={'Real-Time Collaboration'}
                            >
                                <Paragraph>
                                    Discord allows seamless communication and
                                    collaboration, making it easier to
                                    brainstorm, plan, and execute your projects
                                    effectively.
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                key={'support'}
                                title={'Updates and Support'}
                            >
                                <Paragraph>
                                    Stay in the loop with real-time
                                    announcements, guidelines, and support from
                                    our Hackathon coordinators and mentors.
                                </Paragraph>
                            </Accordion>
                        </Accordion.Group>
                    ) : (
                        <>
                            <ul className="list-disc pl-6">
                                <li className="mb-4">
                                    <b>Team Forming:</b> <br />
                                    Looking for like-minded innovators or
                                    missing a key skill in your group? Join our
                                    Discord community to connect with other
                                    participants and form the ultimate team!
                                </li>
                                <li className="mb-4">
                                    <b>Real-Time Collaboration:</b> <br />
                                    Discord allows seamless communication and
                                    collaboration, making it easier to
                                    brainstorm, plan, and execute your projects
                                    effectively.
                                </li>
                                <li>
                                    <b>Updates & Support:</b>
                                    <br /> Stay in the loop with real-time
                                    announcements, guidelines, and support from
                                    our Hackathon coordinators and mentors.
                                </li>
                            </ul>
                            <br />
                        </>
                    )}
                    {/*  */}
                    Ready to Jump In? Click the button below to join our
                    Collabothon Discord community and start your journey of
                    creation and collaboration with like-minded participants
                    now!
                </p>
                <Button
                    href="https://discord.gg/m8FvzUVNqx"
                    htmlAttrs={{ target: '_blank' }}
                >
                    Join Collabothon Discord now!
                </Button>
            </div>
        </div>
    );
};

export default DiscordModal;
