import React from 'react';
import TestimonialCard from '../../testimonial-carousel/TestimonialCard';
import { testimonialData } from '../../testimonial-carousel/TestimonialData';
import TestimonialCarousel from '../../testimonial-carousel/TestimonialCarousel';

const Testimonials = () => {
    return (
        <>
        <div className='pt-20 lg:px-20 bg-primary2'>
            <h1 className="text-center uppercase font-extrabold text-4xl md:text-5xl lg:text-6xl mb-6 lg:mb-24 w-full z-10 text-[#FF018C]">
                TESTIMONIALS
            </h1>
            <div className='w-11/12 m-auto'>
            <TestimonialCarousel>
                {testimonialData.map((testimonial) => {
                    return (
                        <TestimonialCard
                            key={testimonial.id}
                            data={testimonial}
                        />
                    );
                })}
            </TestimonialCarousel>
            </div>
           </div>
        </>
    );
};

export default Testimonials;
