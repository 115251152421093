import React from 'react';
import DiscordLogo from '../../assets/images/discord/discord-mark-black.png';

const Discord = () => {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="pt-20 pb-24 bg-primary2">
            <h1 className="text-center uppercase font-bold text-4xl md:text-5xl lg:text-6xl mb-4 sm:mb-12 w-5/6 m-auto w-full z-10 text-white">
                Join us on{' '}
                <span className="text-[#FF018C] font-extrabold inline-block transform -skew-x-12">
                    Discord
                </span>
            </h1>
            <p
                className="text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white mb-4 sm:mb-12"
                style={{ textAlign: 'left', margin: 'auto', width: '65%' }}
            >
                Stay connected! Join our Discord community to connect with
                participants, form teams, and stay updated on Collabothon
                events. Whether you're seeking teammates or staying informed
                about challenges, Discord is your go-to platform. Our organizing
                team and partners are there to answer your questions and support
                your Collabothon journey!
            </p>
            <div className="flex justify-center sm:justify-center pt-4 sm:pt-0 items-center text-sm">
                <button
                    type="button"
                    className="py-4 px-16 rounded-full bg-yellow-400 hover:bg-yellow-500 duration-200 ease-in-out text-black font-bold cursor-pointer flex items-center justify-center"
                    onClick={() => openInNewTab('https://discord.gg/m8FvzUVNqx')}
                >
                    <img
                        src={DiscordLogo}
                        alt="Discord Logo"
                        className="mr-2 w-6 h-5"
                    />
                    <span>Join us here!</span>
                </button>
            </div>
        </div>
    );
};

export default Discord;
