import {
    interaction___flashOn,
    object___clock,
    object___gear,
    object_document_documentArrow,
    people___businessMale,
    people___groupFemale,
    object_document_document
} from '@lsg/icons';

import TermsAndConditionsPDF from '../../assets/rules and regulations/Collabothon - Terms and Conditions.pdf';

export const rulesData = [
    {
        icon: people___groupFemale,
        title: 'Team Size & Composition',
        description: [
            'Teams must have a minimum of three and a maximum of six members.',
            'We encourage teams to have a diverse composition, including engineers, designers and analysts.',
            'Each team member must submit the registration form individually.',
        ],
    },
    {
        icon: object___clock,
        title: 'Working Time & Submition',
        description: [
            'Participants are encouraged to brainstorm ideas, concepts, and designs once the challenges have been announced.',
            'However, coding should commence only after the official start of the coding phase on Friday at 12 pm.',
            'Participants are allowed to work on their projects until the submission deadline on Sunday at 12 pm.',
            // 'The final submission must be sent to the committee by Sunday at 12 pm.',
            'Any major changes, such as new features or significant redesigns, made after the deadline will not be considered. Minor debugging and fixing of small issues are permitted to a limited extent.',
            // 'The primary focus of the final submission should be the running code.',
        ],
    },
    {
        icon: interaction___flashOn,
        title: 'Challenge',
        description: [
            'Challenges will be partially published before registration opens, providing an overview.',
            'Each team is allowed to submit only one project, addressing a single challenge.',
        ],
    },
    {
        icon: object___gear,
        title: 'Tools & Frameworks',
        description: [
            'The project code must be uploaded to GitHub. A fresh repository should be created no earlier than Friday morning.',
            'Participants are permitted to utilize external tools, frameworks, APIs etc.',
            'However, such tools must either be published under a trial license or be freely usable by all other participants.',
        ],
    },
    {
        icon: object_document_document,
        title: 'Regulations',
        description: [
            'To read our Terms and Conditions please download the file by clicking on the button below.',
        ],
        linkTitle: 'Terms and Conditions', 
        link: TermsAndConditionsPDF,
    },
    {
        icon: people___businessMale,
        title: 'Hackathon Committee',
        description: [
            'The local hackathon committee reserves the right to overrule any rule above in order to achieve the best experience for all parties involved.',
        ],
    }
];