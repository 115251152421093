import e2022img1 from '../../assets/images/gallery/edition_2022/photo_1.jpg';
import e2022img2 from '../../assets/images/gallery/edition_2022/photo_2.jpg';
import e2022img3 from '../../assets/images/gallery/edition_2022/photo_3.jpg';
import e2022img4 from '../../assets/images/gallery/edition_2022/photo_4.jpg';
import e2022img5 from '../../assets/images/gallery/edition_2022/photo_5.jpg';
import e2022img6 from '../../assets/images/gallery/edition_2022/photo_6.jpg';
import e2022img7 from '../../assets/images/gallery/edition_2022/photo_7.jpg';
import e2022img8 from '../../assets/images/gallery/edition_2022/photo_8.jpg';

import e2021img1 from '../../assets/images/gallery/edition_2021/photo_1.jpg';
import e2021img2 from '../../assets/images/gallery/edition_2021/photo_2.jpg';
import e2021img3 from '../../assets/images/gallery/edition_2021/photo_3.jpg';
import e2021img4 from '../../assets/images/gallery/edition_2021/photo_4.jpg';

import e2019img1 from '../../assets/images/gallery/edition_2019/photo_1.jpg';
import e2019img2 from '../../assets/images/gallery/edition_2019/photo_2.jpg';
import e2019img3 from '../../assets/images/gallery/edition_2019/photo_3.jpg';
import e2019img4 from '../../assets/images/gallery/edition_2019/photo_4.jpg';

import e2018img1 from '../../assets/images/gallery/edition_2018/photo_1.jpg';
import e2018img2 from '../../assets/images/gallery/edition_2018/photo_2.jpg';
import e2018img3 from '../../assets/images/gallery/edition_2018/photo_3.jpg';
import e2018img4 from '../../assets/images/gallery/edition_2018/photo_4.jpg';

const defaultHeight = 800;
const defaultWidth = 1200;

export const galleryData = [
    // edition 2022 Lodz
    {
        id: 0,
        src: e2022img1,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 1,
        src: e2022img2,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 2,
        src: e2022img3,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 3,
        src: e2022img4,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 4,
        src: e2022img5,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 5,
        src: e2022img6,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 6,
        src: e2022img7,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 7,
        src: e2022img8,
        title: '#edition2022 Łódź',
        height: defaultHeight,
        width: defaultWidth,
    },
    //  edition 2021 Lodz (online)
    {
        id: 8,
        src: e2021img1,
        title: '#edition2021 Łódź (online)',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 9,
        src: e2021img2,
        title: '#edition2021 Łódź (online)',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 10,
        src: e2021img3,
        title: '#edition2021 Łódź (online)',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 11,
        src: e2021img4,
        title: '#edition2021 Łódź (online)',
        height: defaultHeight,
        width: defaultWidth,
    },
    //  edition 2019 Hamburg
    {
        id: 12,
        src: e2019img1,
        title: '#edition2019 Hamburg',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 13,
        src: e2019img2,
        title: '#edition2019 Hamburg',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 14,
        src: e2019img3,
        title: '#edition2019 Hamburg',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 15,
        src: e2019img4,
        title: '#edition2019 Hamburg',
        height: defaultHeight,
        width: defaultWidth,
    },

    //  edition 2018 Berlin
    {
        id: 16,
        src: e2018img1,
        title: '#edition2018 Berlin',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 17,
        src: e2018img2,
        title: '#edition2018 Berlin',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 18,
        src: e2018img3,
        title: '#edition2018 Berlin',
        height: defaultHeight,
        width: defaultWidth,
    },
    {
        id: 19,
        src: e2018img4,
        title: '#edition2018 Berlin',
        height: defaultHeight,
        width: defaultWidth,
    },
];
