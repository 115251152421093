import React from 'react';
import ParagraphWrapper from '../common/ParagraphWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

const FaqContactUs = () => {
    return (
        <div className="py-8 md:py-16 2xl:w-3/4 mx-auto">
            <div className="px-4 md:px-8 2xl:px-0 text-center">
                <ParagraphWrapper dashedBorderColor={'primary2'}>
                    Do you have more questions which are not covered in the FAQ
                    section above? <br className="hidden xl:block" />
                    Feel free to reach out to us via email. We're committed to
                    promptly responding to your inquiries.
                    <div className="flex flex-wrap justify-center items-center text-primary2 gap-8 mt-6">
                        <div
                            className="p-3 sm:px-6 border border-primary2 rounded-full bg-primary3 
                                        cursor-pointer flex justify-center items-center 
                                        hover:bg-primary1 hover:shadow-xl w-4/5 xs:w-3/4 md:w-fit"
                            onClick={(e) => {
                                window.location.href =
                                    'mailto:collabothon@commerzbank.com';
                                e.preventDefault();
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEnvelopeOpenText}
                                className="mr-3 text-2xl"
                            />
                            <span className="font-black text-xs xs:text-sm md:text-lg ">
                                collabothon
                                <br className="md:hidden" />
                                @commerzbank
                                {/* <br className="md:hidden" /> */}
                                .com
                            </span>
                        </div>
                    </div>
                </ParagraphWrapper>
            </div>
        </div>
    );
};

export default FaqContactUs;
