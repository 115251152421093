import React from 'react';
import { TextHighlight } from '@lsg/components';
import Headline from '../../common/Headline';

const ImpressionCards = () => {
    return (
        <div>
            <div className="bg-cobaLight700 pt-20">
                <Headline
                    headline="Impressions of previous Editions"
                    classNames="text-white"
                />
                <div className="max-w-[1920px] mx-auto">
                    <div className="flex flex-wrap mx-6">
                        <div className="w-full md:w-1/2 2xl:w-1/3 bg-cobaLight md:bg-cobaLight200 2xl:bg-cobaLight200 h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Jasmin"
                                subline="Team JSON Statham, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="Very exciting event with awesome challenges and great collaborations. I loved to participate even as an online team."
                                isLongText
                            />
                        </div>
                        <div className="w-full md:w-1/2 2xl:w-1/3 bg-cobaLight200 md:bg-cobaLight 2xl:bg-cobaLight h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Christopher"
                                subline="Team PapugAI, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="It was an incredibly inspiring event and provided a wonderful opportunity to connect with brilliant minds!"
                                isLongText
                            />
                        </div>
                        <div className="w-full md:w-1/2 2xl:w-1/3 bg-cobaLight md:bg-cobaLight 2xl:bg-cobaLight200 h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Tolga"
                                subline="Team JSON Derulo, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="Great organisation and awesome atmosphere! Our team loved to participate in the Hackathon :) Looking forward to join next years edition!"
                                isLongText
                            />
                        </div>
                        <div className="w-full md:w-1/2 2xl:w-1/3 bg-cobaLight200 md:bg-cobaLight200 2xl:bg-cobaLight h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Karolina"
                                subline="Team QuantumSpark, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="The Collabothon exceeded my expectations. Uniting with brilliant minds to tackle challenges was an unforgettable journey! "
                                isLongText
                            />
                        </div>
                        <div className="w-full md:w-1/2 2xl:w-1/3 bg-cobaLight md:bg-cobaLight200 2xl:bg-cobaLight200 h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Max"
                                subline="Team TechTacular, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="I had a mind-blowing hackathon experience. Working alongside with talented and motivated people to create innovative solutions was an awesome experience."
                                isLongText
                            />
                        </div>
                        <div className="w-full md:w-1/2 2xl:w-1/3  bg-cobaLight200 md:bg-cobaLight 2xl:bg-cobaLight h-[350px] xs:h-64 md:h-72 align-middle p-10">
                            <TextHighlight
                                headline="Dariusz"
                                subline="Team PapugAI, Collabothon 2022"
                                // thumbImgSrc="https://cc-prod.scene7.com/is/image/CCProdAuthor/portrait-photography_P4b_583x389?$pjpeg$&jpegSize=100&wid=585"
                                text="Collabothon shows the strength that bring upon in teamwork and definietely, I have made a schedule appointment for the next event. Knowledge and teamwork is King!"
                                isLongText
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImpressionCards;
