import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
    const [prevPath, setPrevPath] = useState(pathname);

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        if (
            !(
                (prevPath.endsWith('Challenge') ||
                    prevPath.endsWith('challenges')) &&
                (pathname.endsWith('challenges') ||
                    pathname.endsWith('Challenge'))
            )
        ) {
            window.scrollTo(0, 0);
        }
        setPrevPath(pathname);
    }, [pathname]);
};

export default ScrollToTop;
