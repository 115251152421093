import React from 'react';

import FAQ from '../components/faq/FAQ';

const FaqPage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <FAQ />
        </div>
    );
};

export default FaqPage;
