import React, { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import collabothonLogo from '../../assets/images/logo/logo_inverted_color.svg';

import AnimatedDiv from '../animation/AnimatedDiv';
import CountdownBanner from './CountdownBanner';
import RegistrationButton from '../registration/RegistrationButton';

import './MobileHero.css';

const MobileHero = () => {
    const { height, width } = useWindowDimensions();
    const [isRegisterVisible, setIsRegisterVisible] = useState(true);
    const [isCodingVisible, setIsCodingVisible] = useState(true);
    const endRegisterDate = '2024-10-16 00:00 GMT+0200';
    const endCodingDate = '2024-10-20 11:30 GMT+0200';

    return (
        <>
            {/* <div className={'object-cover'}></div> */}
            <div className="relative h-[calc(100vh-64px)] overflow-hidden z-50 bg-image select-none pointer-events-none ">
                {/* Collabothon Logo */}
                <AnimatedDiv
                    child={
                        <img
                            src={collabothonLogo}
                            alt="collabothon logo"
                            className="absolute w-[300px] left-[calc(50%-150px)] md:w-[500px] md:left-[calc(50%-250px)] lg:w-[800px] lg:left-[calc(50%-400px)]"
                        />
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: height * 0.1,
                            transition: { duration: 1.5 },
                        },
                        hidden: { opacity: 0, y: height * 0.5 },
                    }}
                    parentClasses={'z-50'}
                    isBidirectional={false}
                />
                {/* Sub Title */}
                <AnimatedDiv
                    child={
                        <div
                            className="mt-5 absolute text-2xl md:text-3xl lg:text-4xl text-white text-center z-20 font-light pointer-events-auto
                                w-[300px] left-[calc(50%-150px)] md:w-[500px] md:left-[calc(50%-250px)] lg:w-[800px] lg:left-[calc(50%-400px)]"
                        >
                            <div className="mt-3 font-bold transform -skew-x-12 hyphens-none">
                                The international <br />
                                hackaton <br />
                                <span className="inline-block transform skew-x-12">
                                    18-20 October 2024
                                </span>
                            </div>
                            {isRegisterVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-xl md:text-2xl lg:text-3xl text-white text-left font-bold hyphens-none">
                                            Time left to register...
                                            <CountdownBanner
                                                isVisible={isRegisterVisible}
                                                setIsVisible={
                                                    setIsRegisterVisible
                                                }
                                                timeTillDate={endRegisterDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-8">
                                        <div className="flex md:hidden justify-center items-center">
                                            <RegistrationButton
                                                small
                                                buttonLabel={'Register here!'}
                                            />
                                        </div>
                                        <div className="hidden md:flex justify-center items-center">
                                            <RegistrationButton
                                                buttonLabel={'Register here!'}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isRegisterVisible && isCodingVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-xl md:text-2xl lg:text-3xl text-white text-left font-bold hyphens-none">
                                            Coding end in...
                                            <CountdownBanner
                                                isVisible={isCodingVisible}
                                                setIsVisible={
                                                    setIsCodingVisible
                                                }
                                                timeTillDate={endCodingDate}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isRegisterVisible && !isCodingVisible && (
                                <>
                                    <div className="flex justify-center">
                                        <div className="mt-12 text-xl md:text-2xl lg:text-3xl text-white text-center font-bold hyphens-none">
                                            Thank you for joining <br />
                                            Results will be published soon
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: width >= 768 ? height * 0.2 : height * 0.175,
                            transition: { delay: 0.5, duration: 1 },
                        },
                        hidden: { opacity: 0, y: height * 0.35 },
                    }}
                    parentClasses={'z-50'}
                    isBidirectional={false}
                />
            </div>
        </>
    );
};

export default MobileHero;
