import React from 'react';
import {
    VerticalTimeline, VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import 'react-vertical-timeline-component/style.min.css';
import './Timeline.css';
import {Icon} from "@lsg/components";
import {location___location} from "@lsg/icons";

const Timeline = ({data}) => {
    const getTime = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        return `${hours}:${minutes} `;
    };

    return (<VerticalTimeline
            lineColor={'rgb(255, 215, 0)'}
            layout={'2-columns'}
        >
            {data.map((event) => {
                return (<VerticalTimelineElement
                        className="test"
                        key={event.id}
                        style={{width: ''}}
                        contentStyle={{
                            background: 'white',
                            color: 'white',
                            borderRadius: '12px', // boxShadow: 'rgb(221, 221, 221) 4px 6px 10px 0px',
                            // cursor: 'pointer',
                        }}
                        contentArrowStyle={{
                            borderRight: '7px solid  white',
                        }}
                        dateClassName={'event-schedule-date'}
                        icon={<FontAwesomeIcon icon={event.icon}/>}
                        iconStyle={{
                            background: '#1D4857',
                            border: "1px solid white",
                            color: 'white',
                            boxShadow: '0 0 0 2px rgb(0, 46, 60)',
                        }}
                        date={`${getTime(event.startTime)} - ${getTime(event.endTime)} `}
                    >
                        <span className="text-cobaPetrol block">
                            <div className="font-bold sm:text-lg ">
                                {event.description}
                            </div>
                            {event.location && (
                                <div className="sm:text-lg flex ">
                                    <Icon icon={location___location} size={"small"} inline={true} variant={"solid"} color={"primary"}/>
                                    <span>{event.location}</span>
                                </div>
                            )}
                        </span>
                    </VerticalTimelineElement>);
            })}
        </VerticalTimeline>);
};

export default Timeline;
