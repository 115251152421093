import React from 'react';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

import PartnersLogoContainer from './PartnersLogoContainer';
import PartnersMarquee from './PartnersMarquee';
import SupportersLogoContainer from './SupportersLogoContainer';
import Headline from '../common/Headline';

import PartnerInfoCards from './PartnerInfoCards';
import { partnerInfoCardsData } from './partnerInfoCardsData';

import ParagraphWrapper from '../common/ParagraphWrapper';
import FewWavesManyPeaks from '../background-transitions/FewWavesManyPeaks';
import FewWavesLessPeaks from '../background-transitions/FewWavesLessPeaks';

const Partners = () => {
    return (
        <>
            {/* Partners */}
            <div className="max-w-screen-2xl mx-auto py-12 xs:py-24">
                <Headline
                    headline={`Our Partners`}
                    classNames="text-primary2"
                />
                <PartnersLogoContainer />

                <div className="px-4 md:px-8 2xl:px-0">
                    <ParagraphWrapper dashedBorderColor={'primary2'}>
                        <>
                            At Collabothon, collaboration is our heartbeat, and
                            our partners infuse life into this spirit. These
                            visionary companies are driving forces of
                            innovation, committed to positive global impact.
                            Each partner presents a unique challenge for our
                            hackathon participants, igniting innovation and
                            creativity. Yet, their role extends beyond
                            challenges; they are mentors, guiding our
                            development teams toward excellence. In a gesture of
                            appreciation, our partners generously provide prizes
                            and goodies, acknowledging the hard work and
                            dedication of all involved. Collabothon's success is
                            a product of this remarkable partnership. To our
                            partners, your support shapes the event's
                            excellence, and together, we craft an innovation
                            tapestry that leaves a lasting legacy.
                        </>
                    </ParagraphWrapper>
                </div>
            </div>

            {isMobile ? (
                <FewWavesLessPeaks
                    bgColor={'#fff'}
                    waveColor={'#002e3c'}
                />
            ) : (
                <FewWavesManyPeaks
                    bgColor={'#fff'}
                    waveColor={'#002e3c'}
                />
            )}

            {/* Supporters */}
            <div className="bg-primary2">
                <div
                    className="max-w-screen-2xl mx-auto py-12 xs:py-16"
                    id="supportersSection"
                >
                    <Headline
                        headline={'Our Supporters'}
                        classNames="text-primary1"
                    />
                    <div className="px-4 md:px-8 2xl:px-0">
                        <ParagraphWrapper dashedBorderColor={'primary1'}>
                            <>
                                We're grateful for the generous support of our
                                supporters, who provide their goods and services
                                free of charge to help make Collabothon a
                                success. Their contributions help us create an
                                environment that fosters creativity and
                                innovation, allows participants to fully engage
                                in the hackathon experience and focus on
                                developing innovative solutions that have the
                                potential to make a significant impact!
                            </>
                        </ParagraphWrapper>
                    </div>
                    {/* TODO: UNCOMMENT AS SOON AS WE HAVE CLARIFIED THE RIGHTS FOR THE USAGE OF LOGOS ! */}
                    {/* <SupportersLogoContainer /> */}
                </div>

                {isMobile ? (
                    <FewWavesLessPeaks
                        bgColor={'#002e3c'}
                        waveColor={'#fff'}
                    />
                ) : (
                    <FewWavesManyPeaks
                        bgColor={'#002e3c'}
                        waveColor={'#fff'}
                    />
                )}
            </div>

            {/* Call to action */}
            <div
                className="max-w-screen-2xl mx-auto py-12 xs:py-16 mb-24"
                id="becomeSupporterSection"
            >
                <Headline
                    headline="Want to thrive Innovation?"
                    classNames="text-primary2"
                />
                <div className="px-4 md:px-8 2xl:px-0">
                    <ParagraphWrapper dashedBorderColor={'primary2'}>
                        Collabothon is a collaborative event designed to drive
                        innovation and create real impact. We're always on the
                        lookout for like-minded companies who share our passion
                        for innovation and want to make a positive difference in
                        the world. If you're interested in becoming a
                        collaborater or supporter, we'd love to hear from you.
                        Join us and be part of the Collabothon community, where
                        great ideas become reality. Contact us at the email
                        address or phone number below to learn more about how we
                        can work together to make a positive impact through
                        collaboration and innovation.
                        <div className="flex flex-wrap justify-center items-center text-primary2 gap-8 my-4">
                            <div
                                className="p-3 sm:px-6 border border-primary2 rounded-full bg-primary3 
                                        cursor-pointer flex justify-center items-center 
                                        hover:bg-primary1 hover:shadow-xl w-4/5 xs:w-3/4 md:w-fit"
                                onClick={(e) => {
                                    window.location.href =
                                        'mailto:collabothon@commerzbank.com';
                                    e.preventDefault();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelopeOpenText}
                                    className="mr-6 md:mr-3 text-2xl"
                                />
                                <span className="font-black text-xs xs:text-sm md:text-lg ">
                                    collabothon
                                    <br className="md:hidden" />
                                    @commerzbank
                                    {/* <br className="md:hidden" /> */}
                                    .com
                                </span>
                            </div>
                        </div>
                    </ParagraphWrapper>
                </div>

                {/* <PartnersMarquee /> */}
                <div className="mt-24">
                    <PartnerInfoCards data={partnerInfoCardsData} />
                </div>
            </div>
        </>
    );
};

export default Partners;
