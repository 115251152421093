import React from 'react';
import { Video } from '@lsg/components';
import Headline from '../../common/Headline';
import video2023 from '../../../assets/videos/collabothon_Edition2023_compresed.mp4';

const PreviousEditions = () => {
    return (
        <div className="py-12 md:py-20 bg-[#002E3C]">
            <Headline
                headline={
                    <span style={{ color: 'white' }}>
                        CHECK OUT PREVIOUS EDITIONS
                        <a
                            href="https://collabothon.eu/home"
                            target="_blank"
                            style={{
                                color: '#E6007E',
                                textDecoration: 'underline',
                                marginLeft: '15px',
                            }}
                        >
                            HERE
                        </a>
                    </span>
                }
            />
            <div
                className="px-20 mb-0"
                style={{ maxWidth: '1400px', margin: 'auto' }}
            >
                <div style={{ maxWidth: '1400px', margin: 'auto' }}>
                    <Video
                        videoTitle="Edition 2023"
                        src={video2023}
                        textReplayButton="text replay button"
                    />
                    <p
                        className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white"
                        style={{ textAlign: 'left' }}
                    >
                        Once upon a time, starting in Hamburg and expanding to
                        Lodz in 2021, Collabothon has evolved into an annual
                        event. With over 2000 participants to date, each year
                        has been a remarkable journey! Countless innovative
                        ideas have sparked, and tangible projects have come to
                        fruition at Collabothon. Witness the magic for yourself
                        and be part of the 2024 story!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PreviousEditions;
