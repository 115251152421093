import React, { useState } from 'react';
import { PhotoAlbum } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';

import { galleryData } from './galleryData';
import Headline from '../common/Headline';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const GalleryNew = () => {
    const [index, setIndex] = useState(-1);

    const renderPhoto = ({
        photo,
        imageProps: { alt, style, ...restImageProps },
    }) => (
        <div
            style={{
                ...style,
                border: '1px solid #eee',
                borderRadius: '4px',
                // boxSizing: 'content-box',
                // alignItems: 'center',
                // width: style?.width,
                padding: `12px`,
                paddingBottom: `0px`,
                boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
            }}
            className="hover:shadow-[0_6px_6px_2px_rgba(0,0,0,0.2)]"
        >
            <img
                alt={photo.title}
                style={{
                    ...style,
                    width: '100%',
                    padding: 0,
                    objectFit: 'cover',
                    // borderRadius: '4px',
                }}
                {...restImageProps}
            />
            <div className="py-2 text-center font-medium whitespace-nowrap">
                {photo.title}
            </div>
        </div>
    );

    return (
        <div className="">
            <Headline
                headline="Gallery"
                classNames="text-primary2"
            />

            <PhotoAlbum
                layout="rows"
                targetRowHeight={250}
                photos={galleryData}
                onClick={({ index }) => setIndex(index)}
                spacing={20}
                padding={12}
                renderPhoto={renderPhoto}
            />

            <Lightbox
                slides={galleryData}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    );
};
export default GalleryNew;
