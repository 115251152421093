import React from 'react';

import RulesAndFaq from '../components/rules/RulesAndFaq';

const RulesPage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <RulesAndFaq />
        </div>
    );
};

export default RulesPage;
