import { Video } from '@lsg/components';
import videoImpression from '../../../assets/videos/collabothon_2022_compressed.mp4';

import React from 'react';

const VideoStage = () => {
    return (
        <div className="bg-cobaLight700 py-12 md:py-20">
            <div className="max-w-[1920px] mx-auto">
                <Video
                    className="px-6 mb-0"
                    videoTitle="Impressions of Collabothon Edition 2022"
                    src={videoImpression}
                    textReplayButton="text replay button"
                />
            </div>
        </div>
    );
};

export default VideoStage;
