import React from 'react';

import Headline from '../../common/Headline';
import RedHatLogo from '../../../assets/images/partners/redhat_logo.png';
import GoogleLogo from '../../../assets/images/partners/Google_Cloud_logo.png';
import NeosferLogo from '../../../assets/images/partners/neosfer_logo.png';
import CvutLogo from '../../../assets/images/partners/cvut-logo.png';
import IBMLogo from '../../../assets/images/partners/IBM_logo.png';

const PartnersSimple = () => {
    return (
        <div className="pt-20 max-w-[1920px] mx-auto bg-white">
            <Headline
                headline="Meet Our Partners"
                classNames="text-primary2"
            />
            <div className="flex justify-center items-center flex-wrap gap-10 xs:gap-16 px-8 md:px-24 xl:px-48 pb-24">
                <img
                    src={RedHatLogo}
                    alt={`Red Hat Logo`}
                    className="h-10 xs:h-12 md:h-20 object-scale-down"
                />
                <img
                    src={GoogleLogo}
                    alt={`Google Logo`}
                    className="h-12 xs:h-16 md:h-24 object-scale-down"
                />
                <img
                    src={NeosferLogo}
                    alt={`Neosfer Logo`}
                    className="h-12 xs:h-16 md:h-24 object-scale-down"
                />
                <img
                    src={IBMLogo}
                    alt={`Placeholder Logo`}
                    className="h-12 xs:h-16 md:h-24 object-scale-down"
                />
                <img
                    src={CvutLogo}
                    alt={`CVUT Logo`}
                    className="h-12 xs:h-16 md:h-24 object-scale-down"
                />

                {/* TODO: REPLACE WITH SOFIA LOCAL PARTNER LOGO */}
                {/* <img
                    src={PlaceHolderLogo}
                    alt={`Placeholder Logo`}
                    className="h-12 xs:h-16 md:h-24 object-scale-down"
                /> */}
            </div>
        </div>
    );
};

export default PartnersSimple;
