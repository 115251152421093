import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CustomCountDown = ({ timeTillDate, timeFormat, setIsVisible }) => {
    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();

    useEffect(() => {
            const then = moment(timeTillDate, timeFormat).utc();
            const now = moment().utc();
            const countdown = moment.duration(then.diff(now));
            setIsVisible(countdown >= 0);
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const then = moment(timeTillDate, timeFormat).utc();
            const now = moment().utc();
            const countdown = moment.duration(then.diff(now));
            const days = Math.floor(countdown.asDays()); // includes months
            const hours = countdown.hours();
            const minutes = countdown.minutes();
            const seconds = countdown.seconds();

            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);

            setIsVisible(countdown >= 0);

            // const countdown = then.diff(now);
            // console.log('then', then);
            // console.log('now', now);
            // console.log('countdown', countdown);
            // setDays(then.diff(now, 'days'));
            // setHours(then.diff(now, 'hours'));
            // setMinutes(then.diff(now, 'minutes'));
            // setSeconds(then.diff(now, 'seconds'));
        }, 1000);

        // component unmount
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [setIsVisible, timeFormat, timeTillDate]);

    return (
        <div className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl my-5 text-primary2 font-normal">
            <div className="flex gap-2 md:gap-4">
                <div className="flex items-center justify-center bg-white rounded-xl px-2 py-7 xl:py-9 min-w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-40">
                    <div className="flex flex-col items-center">
                        <div className='tracking-tighter'>{days}</div>
                        <span className='text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>days</span>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-white rounded-xl px-2 py-7 xl:py-9 min-w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-40">
                    <div className="flex flex-col items-center">
                        <div className='tracking-tighter'>{hours}</div>
                        <span className='text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>hours</span>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-white rounded-xl px-2 py-7 xl:py-9 min-w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-40">
                    <div className="flex flex-col items-center">
                        <div className='tracking-tighter'>{minutes}</div>
                        <span className='text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>minutes</span>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-white rounded-xl px-2 py-7 xl:py-9 min-w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-40">
                    <div className="flex flex-col items-center">
                        <div className='tracking-tighter'>{seconds}</div>
                        <span className='text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>seconds</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomCountDown;
