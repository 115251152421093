import React from 'react';


// windowUtils OpenInNewTab has additional function within for Google Analytics if consent was given by the user.
import { openInNewTab } from '../../utils/windowUtils';

const RegistrationButton = ({ small, buttonLabel }) => {
    return (
        <div
            className={`flex justify-center items-center py-5 px-12
                        hover:cursor-pointer hover:bg-primary1Highlighted duration-200 ease-in-out
                        rounded-full bg-primary1 text-primary2 font-bold
                        ${small === true ? 'text-base' : 'text-lg'}`}
            onClick={() => {
                openInNewTab(
                    'https://events.commerzbank.de/commerzbank/start/collabothon/sites/vr?lng=en'
                );
            }}
        >
            <div>{buttonLabel}</div>
        </div>
    );
};

export default RegistrationButton;
