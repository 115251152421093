import CommerzbankChallengeImg from '../../assets/images/challenges/commerzbank-challenge-cover.jpg';
import GoogleChallengeImg from '../../assets/images/challenges/google-challenge-cover.jpg';

export const ChallengesData = [
    {
        id: 'commerzbankChallenge',
        hashtag: '#InnovativeWidgetSolutions',
        title: 'Unleash your creativity and technical skills to innovate online banking!',
        subtitle: 'powered by Commerzbank AG',
        description:
            'Design cutting-edge widgets designed especially for our Corporate Clients. Your mission is to create interactive, user-friendly, and innovative widgets that enhance user engagement and provide essential information at a glance. Whether it`s a dynamic data visualizer, an interactive event calendar, or a unique social media feed, your widget should be captivate and add value to the user experience. Your solutions should prioritize usability, design, and integration, transforming how corporate users interact with our existing platform. Impress us with your ability to blend aesthetics with functionality and take our corporate clients landing page to the next level!',
        modalDetails:
            "Craft an innovation for web or mobile banking that users don't even know they need - until they experience its game-changing allure. Dive into the realm of possibilities, conjure up a feature that'll leave users wondering how they ever banked without it. The challenge is set: Ignite digital finance with your coding skills and create an unforgettable online banking experience.",
        imgSrc: CommerzbankChallengeImg,
        prize: '2500€ (in-kind)',
    },
];