import React, { useState } from 'react';
import { Accordion, Paragraph } from '@lsg/components';

import './CustomFaqAccordion.css';

const CustomFaqAccordion = ({ faqData, showNumbers }) => {
    const [openFaqItem, setOpenFaqItem] = useState(-1);

    return (
        <Accordion.Group
            onChange={(openIndex) => setOpenFaqItem(openIndex)}
            openIndex={openFaqItem}
        >
            {faqData?.map((faqItem, index) => (
                <Accordion
                    key={faqItem.question}
                    className={openFaqItem === index ? 'accordion' : ''}
                    title={
                        showNumbers
                            ? `${index + 1}.) ${faqItem.question}`
                            : faqItem.question
                    }
                >
                    <Paragraph>{faqItem.answer}</Paragraph>
                </Accordion>
            ))}
        </Accordion.Group>
    );
};

export default CustomFaqAccordion;
