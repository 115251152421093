import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ChallengeSection = ({ companyName, teaser, onClick, bgColor }) => {
    return (
        <>
            <div
                className={`relative group shadow-lg w-full transition ease-in-out ${bgColor} hover:bg-secondary4 duration-700`}
            >
                <h1
                    className="px-10 pt-10 text-center text-2xl xs:text-3xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl font-extrabold uppercase
                                transition ease-in-out text-cobaLight800 group-hover:text-white duration-700 hyphens-none"
                >
                    {companyName}
                    <br />
                    Challenge
                </h1>
                <p className="px-10 pt-10 pb-24 text-lg lg:text-md xl:text-xl 2xl:text-2xl text-gray-800 text-center">
                    {teaser}
                </p>
                <div className="absolute right-4 bottom-8 text-right">
                    <div
                        className="hover:cursor-pointer hover:translate-x-5 ease-in-out duration-500 w-fit mr-5 ml-auto"
                        onClick={onClick}
                    >
                        <div className="text-lg lg:text-md xl:text-xl 2xl:text-xl font-bold pr-10">
                            <FontAwesomeIcon icon={faArrowRight} /> Read More
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChallengeSection;
