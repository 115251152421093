import React, { useEffect } from 'react';
import { Brandbar, Footer, Metabar, Icon } from '@lsg/components';
import './CustomFooter.css';
import {
    social___youtube,
    social___linkedin,
    social___facebook,
    social___instagram,
} from '@lsg/icons';

const CustomFooter = () => {
    const navigationTree = [
        {
            label: 'Terms and Conditions',
            href: '/terms-and-conditions',
        },
        {
            label: 'Privacy Policy',
            href: '/privacy-policy',
        },
        {
            label: 'Imprint',
            href: '/imprint',
        },
        {
            label: 'Cookie Preferences',
            id: '#cookiePrefs',
            href: '/',
        },
    ];

    const SocialMediaLinks = [
        {
            url: 'https://www.youtube.com/@Collabothon',
            icon: social___youtube,
        },
        {
            url: 'https://www.linkedin.com/company/commerzbank-branch-in-poland',
            icon: social___linkedin,
        },
        {
            url: 'https://www.instagram.com/collabothon/',
            icon: social___instagram,
        },
        {
            url: 'https://www.facebook.com/people/Commerzbank-Centrum-Technologii-Cyfrowych-w-Polsce/100063453976458/',
            icon: social___facebook,
        },
    ];

    useEffect(() => {
        const cookieElement = document.getElementById('#cookiePrefs');
        cookieElement.addEventListener('click', function () {
            localStorage.removeItem('analyticsConsent');
        });
    }, []);
    
    return (
        <Footer>
            <Brandbar
                href="https://www.commerzbank.de/"
                slogan="The bank at your side."
                logoHtmlAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
            />
            <Metabar
                navigationTree={navigationTree}
                socialLinks={
                    <div key={'social-media-links'}>
                        <span
                            key={'intro'}
                            className={'links-intro'}
                        >
                        </span>
                        <ul className="links flex flex-row gap-4">
                            {SocialMediaLinks.map((link) => (
                                <li
                                    key={link.url}
                                    className={'link-list'}
                                >
                                <a
                                    href={link.url}
                                    target="_blank"
                                    rel={'noopener noreferrer'}
                                >
                                <Icon
                                    icon={link.icon}
                                    variant={'solid'}
                                ></Icon>
                                </a>
                            </li>
                             ))}
                        </ul>
                    </div>
                }
            />
        </Footer>
    );
};
export default CustomFooter;
