export const eventsData = [
    {
        number: 1,
        // title: 'Build Your Team',
        title: 'Form Your Dream Team',
        header: 'Header 1',
        description:
            'Bring together a dynamic team of 3 to 6 innovators. Diversity in skills and perspectives will be your secret weapon in conquering challenges.',
    },
    {
        number: 2,
        title: 'Secure Your Spot',
        header: 'Header 2',
        description:
            "Register now to secure your place in this unforgettable event. Don't miss the chance to be part of something extraordinary. ",
    },
    {
        number: 3,
        title: 'Make an Impact',
        header: 'Header 3',
        description:
            'From October 18th to October 20th, immerse yourself in a journey of collaboration, ideation, and innovation. With your team, craft solutions that have the power to change lives and shape a better world.',
    },
];
