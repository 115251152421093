import React from 'react';

const Headline = ({ headline, classNames }) => {
    return (
        <h1
            className={`text-center uppercase font-extrabold px-12 sm:px-6 text-2xl xs:text-4xl lg:text-6xl mb-12 sm:mb-24 w-full z-10 ${classNames}`}
        >
            {headline}
        </h1>
    );
};

export default Headline;
