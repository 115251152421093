import React from 'react';
import {
    A11yMeaningfulLabel,
    Button,
    Cards,
    CardsCustomItem,
    Headline,
    Icon,
} from '@lsg/components';

// import '../../../App.css';
import './Rule.css';

const Rule = (props) => {
    if (!props.data) return;
    const custom = true; // toggle to get a different styling

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return custom ? (
        <>
            <span
                className={
                    'mx-auto flip-card ' + (props.clicked ? 'clicked' : '')
                }
            >
                <CardsCustomItem
                    className="rule-card front-card"
                    centeredLayout={true}
                    spacing={'large'}
                >
                    <Icon
                        icon={props.data.icon}
                        size="large"
                        color={'white'}
                    />
                    <Headline size="h4">
                        <A11yMeaningfulLabel
                            onClick={() => props.onClick(props.id)}
                        >
                            {props.data.title}
                        </A11yMeaningfulLabel>
                    </Headline>
                </CardsCustomItem>
                <CardsCustomItem
                    className="rule-card back-card"
                    contentTop={
                        <div className="p-2 sm:p-6">
                            <div className="flex">
                                <Icon
                                    icon={props.data.icon}
                                    size="small"
                                    inline={true}
                                    color={'primary'}
                                    className="p-4 items-center border rounded-full border-primary1 bg-primary2"
                                />
                                <div className="flex ml-4 text-center text-base sm:text-xs md:text-base xl:text-base 2xl:text-lg font-bold border rounded-full border-primary2 w-full">
                                    <div className="my-auto mx-auto">
                                        {props.data.title}
                                    </div>
                                </div>
                            </div>
                            <Headline size="h4">
                                <A11yMeaningfulLabel
                                    onClick={() => props.onClick(props.id)}
                                ></A11yMeaningfulLabel>
                            </Headline>
                            <div
                                className={
                                    'p-4 text-base sm:text-xs md:text-sm lg:text-xs xl:text-sm 2xl:text-base'
                                }
                            >
                                {props.data.description &&
                                    props.data.description.map((item) => {
                                        return (
                                            <div
                                                key={item}
                                                className="my-2"
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                {props.data.link && props.data.linkTitle && (
                                    <div className="flex flex-col justify-end text-center text-base sm:text-xs md:text-base xl:text-base 2xl:text-lg w-full h-full p-5">
                                        <div className="my-auto mx-auto">
                                            <Button
                                                look="secondary"
                                                onClick={() =>
                                                    openInNewTab(
                                                        props.data.link
                                                    )
                                                }
                                            >
                                                {props.data.linkTitle}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                ></CardsCustomItem>
                {/*</Theme>*/}
            </span>
        </>
    ) : (
        <Cards.Item
            className="basic-card"
            thumbIcon={props.data.icon}
            centeredLayout={true}
            look="noneActiveCard"
        >
            <span
                dangerouslySetInnerHTML={{ __html: props.data.description }}
            />
        </Cards.Item>
    );
};

export default Rule;
