import React from 'react';
import CustomCountDown from './CustomCountDown';
import './CountdownBanner.css';

const CountdownBanner = ({
    setIsVisible,
    timeTillDate = '2024-10-20 11:30 GMT+0200',
}) => {
    const timeFormat = 'YYYY-MM-DD HH:mm Z';

    return (
        <CustomCountDown
            timeTillDate={timeTillDate}
            timeFormat={timeFormat}
            setIsVisible={setIsVisible}
        />
    );
};

export default CountdownBanner;
