import React, { useState } from 'react';
import { Cards, Section } from '@lsg/components';

import Rule from './rule/Rule';
import Headline from '../common/Headline';
import CustomFaqAccordion from '../custom-faq-accordion/CustomFaqAccordion';
import { rulesData } from './rulesData';
import { faqData } from './faqData';

import './RulesAndFaq.css';
import FaqContactUs from '../faq-contact-us/FaqContactUs';

const RulesAndFaq = () => {
    const [clickedRules, setClickedRules] = useState(
        rulesData.map((_) => false)
    );

    return (
        <>
            <Headline
                headline={'Rules'}
                classNames="text-primary2"
            />
            <Cards>
                {rulesData.map((rule, index) => (
                    <Rule
                        id={index}
                        key={rule.title}
                        data={rule}
                        clicked={clickedRules[index]}
                        onClick={(id) => {
                            setClickedRules((prev) =>
                                prev.map((rule, i) => (i === id ? !rule : rule))
                            );
                        }}
                    />
                ))}
            </Cards>
            <Section>
                <Headline
                    headline="F.A.Q's for Rules & Regulations"
                    classNames="text-primary2 hyphens-none"
                />
                <CustomFaqAccordion faqData={faqData} />
            </Section>

            <FaqContactUs />
        </>
    );
};

export default RulesAndFaq;
