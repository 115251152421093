import React from 'react';

import LegalDisclosure from '../components/legal-disclosure/LegalDisclosure';

const LegalDisclosurePage = () => {
    return (
        <div className="max-w-[1920px] mx-auto">
            <LegalDisclosure />
        </div>
    );
};

export default LegalDisclosurePage;
