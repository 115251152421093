import React from 'react';

import GoogleLogo from '../../assets/images/partners/Google_Cloud_logo.png';
import RedHatLogo from '../../assets/images/partners/redhat_logo.png';
import NeosferLogo from '../../assets/images/partners/neosfer_logo.png';
import OreillyLogo from '../../assets/images/partners/oreilly_logo.png';
import CvutLogo from '../../assets/images/partners/cvut-logo.png';
import IBMLogo from '../../assets/images/partners/IBM_logo.png';

const LogoContainer = ({ imgSrc, imgAlt }) => {
    return (
        <div className="group relative z-10">
            <div
                className="bg-white w-36 h-20 md:w-48 md:h-28 px-2
                        relative inline-block overflow-hidden border rounded-xl cursor-pointer
                        shadow-md duration-500 transition-shadow ease-in-out hover:shadow-xl "
            >
                <div>
                    <img
                        src={imgSrc}
                        alt={`${imgAlt} Grayscale`}
                        className="h-20 md:h-28 object-scale-down grayscale w-full translate-y-0 duration-500
                    group-hover:-translate-y-full group-hover:duration-500"
                    />
                    <img
                        src={imgSrc}
                        alt={imgAlt}
                        className="h-20 md:h-28 object-scale-down w-full translate-y-0 duration-500
                    group-hover:-translate-y-full group-hover:duration-500"
                    />
                </div>
            </div>
            <div
                className={`opacity-0 absolute top-2 left-2 -right-3 -bottom-2 border-2 border-primary1 border-dashed rounded-xl -z-10
                            md:group-hover:opacity-100 md:group-hover:duration-500`}
            ></div>
        </div>
    );
};

const PartnersLogoContainer = () => {
    return (
        <div className="flex justify-center flex-wrap gap-6 md:gap-12 mb-12">
            <LogoContainer
                imgSrc={GoogleLogo}
                imgAlt={'Google Logo'}
            />
            <LogoContainer
                imgSrc={RedHatLogo}
                imgAlt={'RedHat Logo'}
            />
            <LogoContainer
                imgSrc={NeosferLogo}
                imgAlt={'Neosfer Logo'}
            />
            <LogoContainer
                imgSrc={IBMLogo}
                imgAlt={'Lodz Logo'}
            />
            <LogoContainer
                imgSrc={CvutLogo}
                imgAlt={'Lodz Logo'}
            />
            {/* TODO: REPLACE WITH SOFIA LOCAL PARTNER LOGO */}
            {/* <LogoContainer
                imgSrc={OreillyLogo}
                imgAlt={'O Reilly Logo'}
            /> */}
        </div>
    );
};

export default PartnersLogoContainer;
