import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronCircleLeft,
    faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './CustomCarousel.css';

const CustomCarousel = ({ children }) => {
    const arrowStyles =
        'flex justify-center items-center p-3 opacity-100 hover:opacity-100 md:transition-all md:duration-500 md:ease-in-out cursor-pointer z-20';
    return (
        <Carousel
            // autoPlay
            infiniteLoop
            axis="horizontal"
            showStatus={false}
            className="relative group"
            showThumbs={false}
            renderArrowPrev={(clickHandler) => {
                return (
                    <div
                        className={`absolute top-0 bottom-0 -left-3 ${arrowStyles}`}
                        onClick={clickHandler}
                    >
                        <FontAwesomeIcon
                            icon={faChevronCircleLeft}
                            className="text-primary1 p-0.5 bg-primary2 rounded-full"
                            size="2x"
                        />
                    </div>
                );
            }}
            renderArrowNext={(clickHandler) => {
                return (
                    <div
                        className={`absolute top-0 bottom-0 -right-3 ${arrowStyles}`}
                        onClick={clickHandler}
                    >
                        <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="text-primary1 p-0.5 bg-primary2 rounded-full"
                            size="2x"
                        />
                    </div>
                );
            }}
        >
            {children}
        </Carousel>
    );
};

export default CustomCarousel;
