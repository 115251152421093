import {
    faAddressCard,
    faComment,
    faComments,
    faCompass,
    faScrewdriverWrench,
    faCode,
    faBowlFood,
    faMugHot,
    faHeadset,
    faFilePowerpoint,
    faPersonChalkboard,
    faAward,
    faMusic,
    faHourglassEnd,
    faChampagneGlasses,
} from '@fortawesome/free-solid-svg-icons';

export const days = [
    {
        id: 0,
        date: '2024-10-18',
        dayOfWeek: 'Friday',
    },
    {
        id: 1,
        date: '2024-10-19',
        dayOfWeek: 'Saturday',
    },
    {
        id: 2,
        date: '2024-10-20',
        dayOfWeek: 'Sunday',
    },
];

export const eventsData = [
    {
        country: 'Germany',
        schedule: {
            friday: [
                {
                    id: 0,
                    startTime: '2023-10-20T09:00:00',
                    endTime: '2023-10-20T09:30:00',
                    description: 'Registration',
                    icon: faAddressCard,
                },
                {
                    id: 1,
                    startTime: '2023-10-20T10:00:00',
                    endTime: '2023-10-20T10:30:00',
                    description: 'Welcome speeches and office round',
                    icon: faComment,
                },
                {
                    id: 2,
                    startTime: '2023-10-20T10:30:00',
                    endTime: '2023-10-20T11:00:00',
                    description: 'Infrastructure setup + preparation for coding',
                    icon: faComment,
                },                
                {
                    id: 3,
                    startTime: '2023-10-20T11:00:00',
                    endTime: '2023-10-20T00:00:00',
                    description: 'Start of Coding (open end)',
                    icon: faCode,
                },
                {
                    id: 4,
                    startTime: '2023-10-20T12:00:00',
                    endTime: '2023-10-20T13:00:00',
                    description: 'Lunch Break',
                    icon: faBowlFood,
                },
                // {
                //     id: 5,
                //     startTime: '2023-10-20T17:00:00',
                //     endTime: '2023-10-20T18:00:00',
                //     description: 'Entertainment Break',
                //     icon: faMusic,
                // },
            ],
            saturday: [
                {
                    id: 0,
                    startTime: '2023-10-21T09:00:00',
                    endTime: '2023-10-21T09:30:00',
                    description: 'Kick of Day 2',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-21T10:30:00',
                    endTime: '2023-10-21T10:45:00',
                    description: 'Call with DTCs',
                    icon: faHeadset,
                },
                {
                    id: 2,
                    startTime: '2023-10-21T12:00:00',
                    endTime: '2023-10-21T13:00:00',
                    description: 'Lunch Break',
                    icon: faBowlFood,
                },
                {
                    id: 3,
                    startTime: '2023-10-21T14:00:00',
                    endTime: '2023-10-21T17:00:00',
                    description: 'Feedback Sessions',
                    icon: faComments,
                },
                {
                    id: 4,
                    startTime: '2023-10-21T19:00:00',
                    endTime: '2023-10-21T21:00:00',
                    description: 'Dinner & Drinks',
                    icon: faBowlFood,
                },
            ],
            sunday: [
                {
                    id: 0,
                    startTime: '2023-10-22T09:00:00',
                    endTime: '2023-10-22T09:30:00',
                    description: 'Kick of Day 3',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-22T12:00:00',
                    endTime: '2023-10-22T12:00:00',
                    description: 'End of Coding',
                    icon: faCode,
                },
                {
                    id: 2,
                    startTime: '2023-10-22T12:00:00',
                    endTime: '2023-10-22T13:00:00',
                    description: 'Lunch Break',
                    icon: faBowlFood,
                },
                {
                    id: 3,
                    startTime: '2023-10-22T13:00:00',
                    endTime: '2023-10-22T15:00:00',
                    description: 'Presentation of Results',
                    icon: faPersonChalkboard,
                },
                {
                    id: 4,
                    startTime: '2023-10-22T16:00:00',
                    endTime: '2023-10-22T17:00:00',
                    description: 'Announcement of Winners',
                    icon: faAward,
                },
                {
                    id: 5,
                    startTime: '2023-10-22T17:00:00',
                    endTime: '2023-10-22T19:00:00',
                    description: 'After Party',
                    icon: faChampagneGlasses,
                },
                {
                    id: 6,
                    startTime: '2023-10-22T19:00:00',
                    endTime: '2023-10-22T19:00:00',
                    description: 'End of Collabothon',
                    icon: faHourglassEnd,
                },
            ],
        },
    },
    {
        country: 'Poland',
        schedule: {
            friday: [
                {
                    id: 0,
                    startTime: '2023-10-20T09:00:00',
                    endTime: '2023-10-20T09:30:00',
                    description: 'Registration',
                    icon: faAddressCard,
                },
                {
                    id: 1,
                    startTime: '2023-10-20T10:00:00',
                    endTime: '2023-10-20T10:30:00',
                    description: 'Welcome speeches and office round ',
                    icon: faComment,
                },
                {
                    id: 2,
                    startTime: '2023-10-20T10:30:00',
                    endTime: '2023-10-20T11:00:00',
                    description: 'Infrastructure setup + preparation for coding',
                    icon: faScrewdriverWrench,
                },
                {
                    id: 3,
                    startTime: '2023-10-20T11:00:00',
                    endTime: '2023-10-20T00:00:00',
                    description: 'Start of Coding',
                    icon: faCode,
                },
                {
                    id: 4,
                    startTime: '2023-10-20T12:00:00',
                    endTime: '2023-10-20T13:30:00',
                    description: 'Collabothon game for participants',
                    icon: faAward,
                },                
                {
                    id: 5,
                    startTime: '2023-10-20T18:00:00',
                    endTime: '2023-10-20T00:00:00',
                    description: 'Pizza & entertainment',
                    icon: faMusic,
                },
            ],
            saturday: [
                {
                    id: 0,
                    startTime: '2023-10-21T09:00:00',
                    endTime: '2023-10-21T09:30:00',
                    description: 'Kick off of the Day 2',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-21T09:30:00',
                    endTime: '2023-10-21T18:00:00',
                    description: 'Time for coding',
                    icon: faCode,
                },
                {
                    id: 2,
                    startTime: '2023-10-21T15:00:00',
                    endTime: '2023-10-21T18:00:00',
                    description: 'Feedback Sessions',
                    icon: faComments,
                },
            ],
            sunday: [
                {
                    id: 0,
                    startTime: '2023-10-22T09:00:00',
                    endTime: '2023-10-22T09:30:00',
                    description: 'Kick of Day 3',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-22T09:30:00',
                    endTime: '2023-10-22T11:30:00',
                    description:
                        'Time for coding + Presentation preparation',
                    icon: faFilePowerpoint,
                },
                {
                    id: 2,
                    startTime: '2023-10-22T11:30:00',
                    endTime: '2023-10-22T11:30:00',
                    description: 'End of Coding',
                    icon: faCode,
                },
                {
                    id: 3,
                    startTime: '2023-10-22T11:30:00',
                    endTime: '2023-10-22T15:00:00',
                    description: 'Presentation of results',
                    icon: faPersonChalkboard,
                },
                {
                    id: 4,
                    startTime: '2023-10-22T15:00:00',
                    endTime: '2023-10-22T16:00:00',
                    description: 'Jury discussion and decision',
                    icon: faComments,
                },
                {
                    id: 5,
                    startTime: '2023-10-22T15:00:00',
                    endTime: '2023-10-22T16:00:00',
                    description: 'Office Tour',
                    icon: faCompass,
                },
                {
                    id: 6,
                    startTime: '2023-10-22T16:00:00',
                    endTime: '2023-10-22T17:00:00',
                    description: 'Announcement of Winners',
                    icon: faAward,
                },                 
                {
                    id: 7,
                    startTime: '2023-10-22T17:00:00',
                    endTime: '2023-10-22T18:00:00',
                    description: 'Networking & celebration',
                    icon: faMusic,
                },
            ],
        },
    },
    {
        country: 'Bulgaria',
        schedule: {
            friday: [
                {
                    id: 0,
                    startTime: '2023-10-20T10:00:00',
                    endTime: '2023-10-20T11:00:00',
                    description: 'Registration & Check-In',
                    icon: faAddressCard,
                },
                {
                    id: 1,
                    startTime: '2023-10-20T11:00:00',
                    endTime: '2023-10-20T12:00:00',
                    description: 'Introduction & Welcome Speeches',
                    icon: faComment,
                },
                {
                    id: 2,
                    startTime: '2023-10-20T12:00:00',
                    endTime: '2023-10-20T12:30:00',
                    description: 'Infrastructure & Setup',
                    icon: faScrewdriverWrench,
                },
                {
                    id: 3,
                    startTime: '2023-10-20T12:30:00',
                    endTime: '2023-10-20T00:00:00',
                    description: 'Start of Coding (open end)',
                    icon: faCode,
                },
                {
                    id: 4,
                    startTime: '2023-10-20T14:30:00',
                    endTime: '2023-10-20T16:00:00',
                    description:
                        'Lunch and munch with Maria\'s World Foundation - Workshop "Cookies"',
                    icon: faBowlFood,
                },
            ],
            saturday: [
                {
                    id: 0,
                    startTime: '2023-10-21T10:00:00',
                    endTime: '2023-10-21T11:30:00',
                    description: 'Kick of Day 2 & Breakfast',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-21T11:30:00',
                    endTime: '2023-10-21T12:00:00',
                    description: 'Call with DTCs',
                    icon: faHeadset,
                },
                {
                    id: 2,
                    startTime: '2023-10-21T15:00:00',
                    endTime: '2023-10-21T18:00:00',
                    description: 'Feedback Sessions',
                    icon: faComments,
                },
                {
                    id: 3,
                    startTime: '2023-10-21T19:00:00',
                    endTime: '2023-10-21T21:00:00',
                    description: 'Entertainment',
                    icon: faMusic,
                },
            ],
            sunday: [
                {
                    id: 0,
                    startTime: '2023-10-22T10:00:00',
                    endTime: '2023-10-22T11:30:00',
                    description: 'Kick of Day 3 & Breakfast',
                    icon: faMugHot,
                },
                {
                    id: 1,
                    startTime: '2023-10-22T11:30:00',
                    endTime: '2023-10-22T13:00:00',
                    description:
                        'Time for Coding and Preparation of Presentations',
                    icon: faFilePowerpoint,
                },
                {
                    id: 2,
                    startTime: '2023-10-22T13:00:00',
                    endTime: '2023-10-22T13:00:00',
                    description: 'End of Coding',
                    icon: faCode,
                },
                {
                    id: 3,
                    startTime: '2023-10-22T13:15:00',
                    endTime: '2023-10-22T16:00:00',
                    description: 'Presentation of Results',
                    icon: faPersonChalkboard,
                },
                {
                    id: 4,
                    startTime: '2023-10-22T17:00:00',
                    endTime: '2023-10-22T18:00:00',
                    description: 'Announcement of Winners',
                    icon: faAward,
                },
                {
                    id: 5,
                    startTime: '2023-10-22T19:30:00',
                    endTime: '2023-10-22T22:00:00',
                    description: 'Entertainment',
                    icon: faMusic,
                },
            ],
        },
    },
    {
        country: 'CzechRepublic',
        schedule: {
            friday: [
                {
                    id: 0,
                    startTime: '2023-10-20T09:00:00',
                    endTime: '2023-10-20T10:00:00',
                    description: 'Registration & Check-In',
                    icon: faAddressCard,
                    location: 'National Library of Technology',
                },
                {
                    id: 1,
                    startTime: '2023-10-20T10:00:00',
                    endTime: '2023-10-20T11:00:00',
                    description: 'Introduction & Welcome Speeches',
                    icon: faComment,
                },
                {
                    id: 2,
                    startTime: '2023-10-20T11:00:00',
                    endTime: '2023-10-20T11:30:00',
                    description: 'Infrastructure & Setup',
                    icon: faScrewdriverWrench,
                },
                {
                    id: 3,
                    startTime: '2023-10-20T11:30:00',
                    endTime: '2023-10-20T00:00:00',
                    description: 'Start of Coding (open end)',
                    icon: faCode,
                    location: 'Fakulta informačních technologií ČVUT',
                },
                {
                    id: 4,
                    startTime: '2023-10-20T13:30:00',
                    endTime: '2023-10-20T15:00:00',
                    description: 'Lunch Break',
                    icon: faBowlFood,
                },
                {
                    id: 5,
                    startTime: '2023-10-20T19:00:00',
                    endTime: '2023-10-20T23:00:00',
                    description: 'Entertainment',
                    icon: faMusic,
                },
            ],
            saturday: [
                {
                    id: 0,
                    startTime: '2023-10-21T09:00:00',
                    endTime: '2023-10-21T10:30:00',
                    description: 'Kick of Day 2 & Breakfast',
                    icon: faMugHot,
                    location: 'Fakulta informačních technologií ČVUT',
                },
                {
                    id: 1,
                    startTime: '2023-10-21T10:30:00',
                    endTime: '2023-10-21T11:00:00',
                    description: 'Call with DTCs',
                    icon: faHeadset,
                },
                {
                    id: 2,
                    startTime: '2023-10-21T14:00:00',
                    endTime: '2023-10-21T17:00:00',
                    description: 'Feedback Sessions',
                    icon: faComments,
                },
            ],
            sunday: [
                {
                    id: 0,
                    startTime: '2023-10-22T09:00:00',
                    endTime: '2023-10-22T10:30:00',
                    description: 'Kick of Day 3 & Breakfast',
                    icon: faMugHot,
                    location: 'Fakulta informačních technologií ČVUT',
                },
                {
                    id: 1,
                    startTime: '2023-10-22T10:30:00',
                    endTime: '2023-10-22T12:00:00',
                    description:
                        'Time for Coding and Preparation of Presentations',
                    icon: faFilePowerpoint,
                },
                {
                    id: 2,
                    startTime: '2023-10-22T12:00:00',
                    endTime: '2023-10-22T12:00:00',
                    description: 'End of Coding',
                    icon: faCode,
                },
                {
                    id: 3,
                    startTime: '2023-10-22T12:15:00',
                    endTime: '2023-10-22T15:00:00',
                    description: 'Presentation of Results',
                    icon: faPersonChalkboard,
                },
                {
                    id: 4,
                    startTime: '2023-10-22T16:00:00',
                    endTime: '2023-10-22T17:00:00',
                    description: 'Announcement of Winners',
                    icon: faAward,
                },
                {
                    id: 5,
                    startTime: '2023-10-22T18:30:00',
                    endTime: '2023-10-22T21:00:00',
                    description: 'Networking & cellebration',
                    icon: faMusic,
                },
            ],
        },
    },
];
