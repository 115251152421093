import React from 'react';
import Marquee from 'react-fast-marquee';

const CustomMarquee = ({ data, showText, showImg, textClasses }) => {
    return (
        <Marquee
            speed={50}
            gradient={true}
        >
            {showImg &&
                data.map((item) => (
                    <div
                        key={item.id}
                        className="flex mx-6 my-auto"
                    >
                        <img
                            src={item.image}
                            alt="Logo"
                            className="h-16 my-auto"
                        />
                    </div>
                ))}
            {showText &&
                data.map((item) => (
                    <div
                        key={item.id}
                        className={`${textClasses} flex mx-6 my-auto`}
                    >
                        <div className="h-16 my-auto">{item.text}</div>
                    </div>
                ))}
        </Marquee>
    );
};

export default CustomMarquee;
