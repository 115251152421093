import React from 'react';
import Headline from '../common/Headline';
import LocationMap from '../../assets/images/location_map.png';

const LocationsHeader = () => {
    return (
        <div className="py-12 md:py-20 bg-[#002E3C]">
            <Headline
                headline={
                    <span style={{ color: 'white' }}>
                        THIS YEAR'S{' '}
                        <span style={{ color: '#E6007E' }}>LOCATION</span>
                    </span>
                }
            />
            <img
                src={LocationMap}
                alt="Location Map"
                className="w-full mt-8"
            />
        </div>
    );
};

export default LocationsHeader;
