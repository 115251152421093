import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import collabothonLogo from '../../../assets/images/logo/logo_inverted_color.svg';

import AnimatedDiv from '../animation/AnimatedDiv';
import CountdownBanner from './CountdownBanner';
import RegistrationButton from '../registration-info/RegistrationButton-copy';

import cloud1 from '../../../assets/images/hero/Group 29cloud_.png';
import cloud2 from '../../../assets/images/hero/Group 28cloud_.png';
import cloud3 from '../../../assets/images/hero/Group 27cloud_.png';
import cloud4 from '../../../assets/images/hero/Group 26cloud_.png';
import cloud5 from '../../../assets/images/hero/Group 25cloud_.png';
import cloud6 from '../../../assets/images/hero/Group 24cloud_.png';
import cloud7 from '../../../assets/images/hero/Group 23cloud_.png';
import cloud8 from '../../../assets/images/hero/Group 22cloud_.png';
import cloud9 from '../../../assets/images/hero/Group 21cloud_.png';
import cloud10 from '../../../assets/images/hero/Group 20cloud_.png';
import cloud11 from '../../../assets/images/hero/Group 19cloud_.png';
import cloud12 from '../../../assets/images/hero/Group 18cloud_.png';
import cloud13 from '../../../assets/images/hero/Group 17cloud_.png';
import cloud14 from '../../../assets/images/hero/Group 16cloud_.png';
import cloud15 from '../../../assets/images/hero/Group 15cloud_.png';
import cloud16 from '../../../assets/images/hero/Group 14cloud_.png';
import cloud17 from '../../../assets/images/hero/Group 13cloud_.png';

const MobileHero = () => {
    const { height, width } = useWindowDimensions();

    const navbarHeightMobile = 64;
    const heightReduceFactor = 2; // higher value = clouds will be lower in the end
    const cloudClass =
        'z-0 absolute w-full h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] overflow-hidden m-auto left-0 right-0 object-cover';
    return (
        <>
            {/* <div className={'object-cover'}></div> */}
            <div className="relative h-[calc(100vh-64px)] overflow-hidden z-50 bg-[#01687a] select-none pointer-events-none">
                {/* Collabothon Logo */}
                <AnimatedDiv
                    child={
                        <img
                            src={collabothonLogo}
                            alt="collabothon logo"
                            className="absolute w-[300px] left-[calc(50%-150px)] md:w-[500px] md:left-[calc(50%-250px)] lg:w-[800px] lg:left-[calc(50%-400px)]"
                        />
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: height * 0.1,
                            transition: { duration: 1.5 },
                        },
                        hidden: { opacity: 0, y: height * 0.5 },
                    }}
                    parentClasses={'z-50'}
                    isBidirectional={false}
                />
                {/* Sub Title */}
                <AnimatedDiv
                    child={
                        <div
                            className="mt-5 absolute text-2xl md:text-3xl lg:text-5xl text-white text-center z-20 font-light pointer-events-auto
                                w-[300px] left-[calc(50%-150px)] md:w-[500px] md:left-[calc(50%-250px)] lg:w-[800px] lg:left-[calc(50%-400px)]"
                        >
                            <div className="text-4xl md:text-5xl lg:text-6xl text-white text-center font-extrabold hyphens-none">
                                SKY IS THE LIMIT?
                            </div>
                            <div className="mt-12 font-extralight hyphens-none">
                                The international <br />
                                tech hackathon from <br />
                                October 20th to 22nd 2023!
                            </div>
                            <div className="my-8">
                                <div className="flex md:hidden justify-center items-center">
                                    <RegistrationButton
                                        small
                                        buttonLabel={'Register now'}
                                    />
                                </div>
                                <div className="hidden md:flex justify-center items-center">
                                    <RegistrationButton
                                        buttonLabel={'Register now'}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    parentVariant={{
                        visible: {
                            opacity: 1,
                            y: width >= 768 ? height * 0.2 : height * 0.175,
                            transition: { delay: 0.5, duration: 1 },
                        },
                        hidden: { opacity: 0, y: height * 0.35 },
                    }}
                    parentClasses={'z-50'}
                    isBidirectional={false}
                />
                {/* Cloud Images */}
                <div className={'relative w-full pb-[1000%] overflow-hidden'}>
                    <AnimatedDiv
                        // LMC Asset 3
                        child={
                            <img
                                className={cloudClass} // top-[26%]
                                src={cloud1}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.32 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.26,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 1 - mitte links spitz cloud
                        child={
                            <img
                                className={cloudClass} // top-[34%]
                                src={cloud2}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.5 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.3,
                            },
                        }}
                        parentClasses={'z-[19] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 8
                        child={
                            <img
                                className={cloudClass} // top-[42%]
                                src={cloud3}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.48 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.42,
                            },
                        }}
                        parentClasses={'z-[10] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 11 - middle cloud bottom - the important one
                        child={
                            <img
                                className={cloudClass} // top-[36%]
                                src={cloud4}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.4 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.34,
                            },
                        }}
                        parentClasses={'z-[26] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 5 - left cloud
                        child={
                            <img
                                className={cloudClass} // top-[21%]
                                src={cloud5}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.55 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.21,
                            },
                        }}
                        parentClasses={'z-[10] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 1 1
                        child={
                            <img
                                className={cloudClass} // top-[42%]
                                src={cloud6}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.42 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.42,
                            },
                        }}
                        parentClasses={'z-[10] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 2 1
                        child={
                            <img
                                className={cloudClass} // top-[38%]
                                src={cloud7}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.38 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.38,
                            },
                        }}
                        parentClasses={'z-[10] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 8 - middle cloud
                        child={
                            <img
                                className={cloudClass} // top-[23%]
                                src={cloud8}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.35 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.23,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 7
                        child={
                            <img
                                className={cloudClass} // top-[18%]
                                src={cloud9}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.35 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.18,
                            },
                        }}
                        parentClasses={'z-[15] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 1
                        child={
                            <img
                                className={cloudClass} // top-[8%]
                                src={cloud10}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.3 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.08,
                            },
                        }}
                        parentClasses={'z-[15] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 5
                        child={
                            <img
                                className={cloudClass} // top-[17%]
                                src={cloud11}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.28 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.17,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 9 - far left
                        child={
                            <img
                                className={cloudClass} // top-[32%]
                                src={cloud12}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.35 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.32,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 4
                        child={
                            <img
                                className={cloudClass} // top-[14%]
                                src={cloud13}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.4 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.14,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 7
                        child={
                            <img
                                className={cloudClass} // top-[14%]
                                src={cloud14}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.45 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.14,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 10
                        child={
                            <img
                                className={cloudClass} // top-[4%]
                                src={cloud15}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.25 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.04,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 12
                        child={
                            <img
                                className={cloudClass} // top-[8%]
                                src={cloud16}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.32 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: (height - navbarHeightMobile) * 0.08,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                    <AnimatedDiv
                        // LMC Asset 12
                        child={
                            <img
                                className={cloudClass} // top-[0%]
                                src={cloud17}
                                alt={'cloud'}
                            />
                        }
                        parentVariant={{
                            visible: {
                                opacity: 1,
                                y:
                                    (height - navbarHeightMobile) *
                                    0.325 *
                                    heightReduceFactor,
                                transition: { duration: 2 },
                            },
                            hidden: {
                                opacity: 1,
                                y: 0,
                            },
                        }}
                        parentClasses={'z-[20] relative'}
                        isBidirectional={false}
                    />
                </div>
            </div>
            <CountdownBanner />
        </>
    );
};

export default MobileHero;
