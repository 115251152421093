import { FacitiliesOnLeft } from './FacitiliesInfo';
import { FacitiliesOnRight } from './FacitiliesInfo';
import Seagull from '../../assets/videos/mewa_mp4.mp4';
import './VideoBlur.css';

const AvailableFacitilies = () => {
    return (
        <>
            <div className="py-12 md:py-20 bg-primary2 flex flex-col items-center relative z-0">
                <div className="flex flex-col md:flex-row w-8/12 items-center relative z-20">
                    <h1 className="text-4xl xs:text-4xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl font-bold uppercase hyphens-none text-left">
                        <span className="text-white">Available </span>
                        <span className="text-[#E6007E] font-extrabold">
                            Facilities
                        </span>
                    </h1>
                </div>
                <div className="flex flex-col md:flex-row justify-between md:gap-y-4 gap-y-2 w-8/12 mx-auto mt-6 md:mt-12 relative z-20">
                    <ul className="list-disc list-inside text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white text-left md:space-y-4 space-y-2 md:w-[45%]">
                        {FacitiliesOnLeft.map((item, index) => (
                            <li key={index}>{item.text}</li>
                        ))}
                    </ul>
                    <ul className="list-disc list-inside text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white text-left md:space-y-4  space-y-2 md:w-[45%]">
                        {FacitiliesOnRight.map((item, index) => (
                            <li key={index}>{item.text}</li>
                        ))}
                    </ul>
                </div>
                <div className="hidden lg:block absolute top-0 right-0 w-1/2 h-1/2 overflow-hidden z-10">
                    <video
                        className="block w-full h-full object-cover blur-mask"
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    >
                        <source
                            src={Seagull}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className="block lg:hidden w-full h-48 overflow-hidden z-10 mt-6">
                    <video
                        className="block w-full h-full object-cover blur-mask"
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    >
                        <source
                            src={Seagull}
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </>
    );
};

export default AvailableFacitilies;
