export const FacitiliesOnLeft = [
    {
        text: 'Coffee machines with unlimited access to caffeine',
    },
    {
        text: 'Chill zone to unwind after a coding session',
    },
    {
        text: 'Designated workspaces for participants',
    },
    {
        text: 'Possibility to stay overnight',
    },
];

export const FacitiliesOnRight = [
    {
        text: 'Common kitchen with microwave',
    },
    {
        text: 'Appliances for people with special needs - accessible toilets, lift',
    },
    {
        text: 'Shower',
    },
];
