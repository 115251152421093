import React from 'react';

const TestimonialCard = ({ data, onClick }) => {
    if (!data) return null;

    return (
        <div className="lg:grid lg:grid-cols-3 grid-cols-1 text-white w-2/3 mx-auto pb-12">
            <div className="col-span-1 flex justify-center lg:justify-end items-center">
                <img
                    src={data.imgSrc}
                    alt="Partner img"
                    className="object-cover w-[211px] h-[211px] rounded-full"
                />
            </div>

            <div className="col-span-2 px-6 text-center lg:text-left">
                <h2 className="text-xl md:text-3xl font-bold hyphens-none">
                    {data.title}
                </h2>
                <div className="text-lg md:text-xl font-semibold hyphens-none">
                    {data.subtitle}
                </div>
                <div className="text-base md:text-xl text-justify text-pretty hyphens-none">
                    <span className='text-[#FF018C] text-xl md:text-2xl font-bold'>"</span>
                    {data.description}
                    <span className='text-[#FF018C] text-xl md:text-2xl font-bold'>"</span>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
