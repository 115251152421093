export const eventsData = [
    {
        number: 1,
        // title: 'Build Your Team',
        title: 'Form Your Dream Team',
        header: 'Header 1',
        descriptionBeforeLink:
            'Bring together a dynamic team of 3 to 6 innovators. Diversity in skills and perspectives will be your secret weapon in conquering challenges. If you don’t have a team,',
        link: 'https://discord.gg/m8FvzUVNqx',
        linkText: ' join our discord ',
        descriptionAfterLink: 'now!',
    },
    {
        number: 2,
        title: 'Secure Your Spot',
        header: 'Header 2',
        descriptionBeforeLink:
            "Register until 15th October 2024 to secure your place in this unforgettable event. Don't miss the chance to be part of something extraordinary.",
        link: 'https://events.commerzbank.de/commerzbank/start/collabothon/sites/vr?lng=en',
        linkText: ' Click here to register ',
        expireDate: '2024.10.06',
        descriptionAfterLink: 'today!',
    },
    {
        number: 3,
        title: 'Make an Impact!',
        header: 'Header 3',
        descriptionBeforeLink:
            'From October 18th to October 20th 2024, immerse yourself in a journey of collaboration, ideation, and innovation. With your team, craft solutions that have the power to change lives and shape a better world.',
    },
];
