import React from 'react';
import { Section } from '@lsg/components';

import Headline from '../common/Headline';
import CustomFaqAccordion from '../custom-faq-accordion/CustomFaqAccordion';
import FaqContactUs from '../faq-contact-us/FaqContactUs';
import { faqData } from './faqData';

const FAQ = () => {
    return (
        <>
            <Headline
                headline="Frequently Asked Questions"
                classNames="text-primary2"
            />

            <Section>
                <CustomFaqAccordion
                    faqData={faqData}
                    // showNumbers={false}
                />
            </Section>

            <FaqContactUs />
        </>
    );
};

export default FAQ;
