import React from 'react';
import './CountdownBanner.css';

const CountdownBanner = () => {
    return (
        <div
            className="h-fit bg-primary2 w-full
                        xl:w-[1200px] xl:left-[calc(50%-600px)] xl:mb-[2rem]
                        2xl:w-[1500px] 2xl:left-[calc(50%-750px)]
                        "
        >
            <div className="py-4 px-8 md:py-6 md:px-12 lg:py-8 lg:px-16 xl:py-10 xl:px-24">
                <div className="flex flex-wrap xl:flex-nowrap">
                    <div className="w-full text-center">
                        <div className="text-xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold h-12 lg:h-16 xl:h-24">
                            <span className="text-primary1">
                                Thanks for joining&nbsp;
                            </span>
                            <span className="text-white">the Collabothon</span>
                        </div>
                        <div className="hidden md:block text-white text-xs xl:text-base 2xl:text-lg">
                            IN &nbsp;&nbsp; FRANKFURT &nbsp;&nbsp; //
                            &nbsp;&nbsp; LODZ &nbsp;&nbsp; // &nbsp;&nbsp;
                            PRAGUE &nbsp;&nbsp; // &nbsp;&nbsp; SOFIA
                            &nbsp;&nbsp; // &nbsp;&nbsp; 2023
                        </div>
                        <div className="block md:hidden text-white text-xs xl:text-base 2xl:text-lg">
                            IN &nbsp; FRANKFURT &nbsp; // &nbsp; LODZ &nbsp; //
                            &nbsp; PRAGUE &nbsp; // &nbsp; SOFIA &nbsp; //
                            &nbsp; 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownBanner;
