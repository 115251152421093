import React from "react";

const ConsentContext = React.createContext({
    userResponded: false,
    analyticsConsent: false,
    openModal: true,
    setUserResponded: () =>{},
    setAnalyticsConsent: () =>{},
    setOpenModal: () => {}
}
);

export default ConsentContext