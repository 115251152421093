import React from 'react';
import parse from 'html-react-parser';
import { Paragraph } from '@lsg/components';

import { petrol } from '../../constants/colors';
import Trophy from '../../assets/images/collabothon_prize_1.png';
const MAX_SIZE_TEXT = 400;

const ChallengeCard = ({ data, onClick }) => {
    if (!data) return;
    return (
        <div
            className="lg:grid text-white lg:grid-cols-3 grid-cols-1 border border-primary2 overflow-hidden rounded-2xl mb-6
            transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30 mx-4"
            style={{ backgroundColor: petrol }}
        >
            <div className="col-span-1 h-[196px] xs:h-[256px] lg:h-full">
                <img
                    src={data.imgSrc}
                    alt="Partner img"
                    className="object-cover h-[196px] xs:h-[256px] lg:h-full"
                />
                {data.flag && (
                    <div className="absolute w-16 h-fit top-5 left-10 right-0 bottom-0 z-30 text-primary2 ">
                        <div className="border border-primary2 rounded-lg flex overflow-hidden shadow-xl">
                            {data.flag}
                        </div>
                    </div>
                )}
            </div>

            <div className="col-span-2 p-6">
                <div className="flex justify-center lg:justify-start text-center md:text-left max-h-15 h-15 leading-15 text-lg sm:text-3xl text-primary1 font-bold hyphens-none">
                    {data.hashtag}
                </div>

                <div className="flex justify-center lg:justify-start text-center  text-base font-light italic hyphens-none">
                    {data.subtitle}
                </div>
                <h2 className="text-center lg:text-left pt-4 text-sm sm:text-xl text-white font-bold max-w-full sm:max-w-90 hyphens-none">
                    {data.title}
                </h2>
                <Paragraph className="text-center lg:text-left pt-4 text-sm sm:text-lg min-h-[156px] text-white max-w-full sm:max-w-90 hyphens-none">
                    {data.description.length > MAX_SIZE_TEXT
                        ? parse(
                              data.description.substring(0, MAX_SIZE_TEXT - 1) +
                                  '...'
                          )
                        : parse(data.description)}
                </Paragraph>

                <div className="text-xl text-white">
                    <div className="flex justify-center sm:float-left">
                        <img
                            src={Trophy}
                            alt="img 1"
                            className="h-10 w-8 mr-2 items-center"
                        />
                        {/* <div className="flex items-center text-left">
                            <b>Prize:</b>&nbsp;{data.prize}
                        </div> */}
                    </div>

                    {/* <div className="flex justify-center sm:justify-end pt-4 sm:pt-0 items-center text-sm w-full sm:w-auto">
                        <button
                            type="button"
                            className="p-2 px-4 rounded-full bg-primary1 hover:bg-primary1Highlighted duration-200 ease-in-out text-primary2 font-bold cursor-pointer"
                            onClick={onClick}
                        >
                            Read More
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default ChallengeCard;
