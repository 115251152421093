import React from 'react';
import Headline from '../../common/Headline';
import PrizeContainer from './PrizeContainer';

const Prizes = () => {
    return (
        <div className="py-12 md:py-20">
            <Headline
              headline={<span style={{ color:'#002E3C'}}>WIN UP TO <span style={{ color: '#E6007E' }}>10.000€</span> IN AWESOME PRIZES</span>}
            />
            <div className="flex flex-wrap justify-center">
                <div className="w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'3 000€'}
                        title={'<Partner\'s Winners />'}
                        subtitle={'for trainings and valuable goodies'}
                    />
                </div>
                <div className="border-dashed border-t border-b xs:border-none w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'5 000€'}
                        title={'<GrandAward />'}
                        subtitle={'In Amazon vouchers'}
                    />
                </div>
                <div className="w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'2 000€'}
                        title={'<AudienceFavourite />'}
                        subtitle={'in-kind - one team'}
                    />
                </div>
            </div>
            <>
               
            </>
        </div>
    );
};

export default Prizes;
