export const faqData = [
    {
        question: 'What is the recommended team size for Collabothon?',
        answer: 'Teams should consist of at least three and a maximum of six members.',
    },
    {
        question: 'Is there any preferred composition for teams?',
        answer: 'We embrace diverse team compositions, encompassing designers, project managers, coders, and more. However, the final choice is entirely up to you.',
    },
    {
        question: 'Is it mandatory for all teams to be physically present?',
        answer: "YES! For this year's edition full on-site participation is required. To join the event, you must be present at our designated location - Ogrodowa Office in Łódź, Poland.",
    },
    {
        question: 'How do we register our team?',
        answer: 'Visit the home page and click on the "Register now!" button. You will be forwarded to our registration page. Each team member needs to submit the registration form individually. We will match the teams on the basis of the team name field. Therefore, please make sure to avoid typos in your team name.',
    },
    {
        question: 'Can we make changes to the team after registration?',
        answer: 'Changes to the team can be discussed with the committee on a case-by-case basis. Please write us an email with the details to collabothon@commerzbank.com and we will provide you support.',
    },
    {
        question: 'When can we start working on our project?',
        answer: 'You can begin brainstorming and conceptualizing once the challenge is announced. However, the coding can only begin after the official start of the coding phase.',
    },
    {
        question: 'What is the deadline for project submission?',
        answer: 'The submission deadline is on Sunday at 12 pm CEST.',
    },
    {
        question: 'Will we be using our own hardware?',
        answer: 'Yes, you should bring your own device / laptop to work on the challenge.',
    },
    {
        question: 'What are the requirements for project code submission?',
        answer: 'The project code must be uploaded to GitHub in a fresh repository created earliest on Friday morning.',
    },
    {
        question:
            'Are there restrictions on using external tools and frameworks?',
        answer: 'You can use any external tools, frameworks, or APIs as long as they are published under a trial license or freely usable by all participants. Using pre-existing, proprietary code from team members for this event is not allowed.',
    },
    {
        question:
            'Can we make significant changes to the project after the deadline?',
        answer: 'Major changes after the deadline, including new features or significant redesigns, will not be considered. Minor fixes and debugging are allowed to a limited extent.',
    },
    {
        question: 'How will teams be selected for presentation?',
        answer: 'All teams that prepare a prototype of the solution with the working code will be allowed to pitch and present their project during the final presentations.',
    },
    {
        question: 'What are the criteria for judging projects?',
        answer: 'Projects will be evaluated based on fit to challenge/business value, technical implementation/level of complexity, innovation, and design.',
    },
    {
        question: 'How will winners be selected?',
        answer: 'A panel of our experts will collaboratively assess all submissions. They will Partner`s and Grand Award winners. Additionally, participants have the opportunity to vote for their favorite team in the "Audience Favorite" category.',
    },
];
