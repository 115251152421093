import { Accordion, Button, Paragraph } from '@lsg/components';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import ConsentContext from '../../context/consentContext';
import { isMobile } from 'react-device-detect';

// updade the value
const ConsentBanner = () => {
    // set the analyticsConsent in the context to true
    const { setUserResponded, setAnalyticsConsent } =
        useContext(ConsentContext);

    const [openModal, setOpenModal] = useState(true);

    const handleAcceptAll = () => {
        setAnalyticsConsent(true);
        localStorage.setItem('analyticsConsent', true);
        setUserResponded(true);
        setOpenModal(false);
    };

    const handleDecline = () => {
        setAnalyticsConsent(false);
        localStorage.setItem('analyticsConsent', false);
        setUserResponded(true);
        setOpenModal(false);
    };

    return (
        <div>
            {openModal && !localStorage.getItem('analyticsConsent') && (
                <div className="flex justify-center items-center backdrop-blur bg-slate-500/[.7] fixed top-0 left-0 z-[60] w-screen h-screen">
                    <div className=" w-1/3 max-w-[700px] max-h-[90vh] h-[50rem] min-w-[350px] relative bg-white justify-center z-[70] rounded-xl border-cobaLight500 border-4 shadow-2xl overflow-x-hidden pb-10">
                        <div className="px-10 pt-10">
                            {isMobile ? (
                                <h2 className=" text-center text-xl text-cobaLight700 pb-8">
                                    Usage of Cookies & Analytics
                                </h2>
                            ) : (
                                <h1 className=" text-center text-4xl text-cobaLight700 pb-8">
                                    Usage of Cookies & Analytics
                                </h1>
                            )}

                            <p className=" text-sm">
                                We use cookies and Google Analytics to analyze
                                website traffic and optimize your browsing
                                experience. <br />
                                By clicking "Accept", you consent to the use of
                                these technologies to measure reach to improve
                                the website experience and optimize marketing
                                campaigns. You can change your mind and withdraw
                                your consent at any time or find out more by
                                checking the Privacy Policy & Cookie Preferences
                                links in the footer of the home page.
                                <br /> <br />
                            </p>
                            <b>
                                Expand the items below to display further
                                informations.
                            </b>
                        </div>
                        <div className=" px-10 py-8">
                            <Accordion.Group multiple={false}>
                                <Accordion
                                    key={'googleTagManager'}
                                    title={'Google Tag Manager'}
                                >
                                    <Paragraph>
                                        Upon your voluntary and revocable
                                        consent, Commerzbank uses the Google Tag
                                        Manager for the management of various
                                        technologies requiring consent on the
                                        Collabothon website, based on your
                                        consent settings. The provider of the
                                        Google Tag Manager component is Google
                                        Ireland Limited, Google Building Gordon
                                        House, Barrow St, Dublin 4, Ireland.
                                        This service allows for the management
                                        of website tags based on previously made
                                        consent settings. The Google Tag Manager
                                        implements website tags only. Website
                                        tags are small code elements on the
                                        website that serve, among other things,
                                        to measure traffic and visitor behavior,
                                        capture the impact of online advertising
                                        and social channels, employ remarketing
                                        and targeting to specific audiences, or
                                        test and optimize the website. The
                                        Google Tag Manager itself does not use
                                        cookies. When downloading the Google Tag
                                        Manager script from Google, personal
                                        data (including the IP address contained
                                        in a standard HTTP request protocol) are
                                        transmitted to Google and stored for 14
                                        days. This processing is necessary to
                                        ensure the operation of the service. It
                                        has been contractually agreed with
                                        Google that the use of this data serves
                                        exclusively for the purpose of providing
                                        the Google Tag Manager.
                                    </Paragraph>
                                </Accordion>
                                <Accordion
                                    key={'googleAnalytics'}
                                    title={'Google Analytics'}
                                >
                                    <Paragraph>
                                        With your voluntary and revocable
                                        consent, Commerzbank is authorized to
                                        use "Google Analytics" on its website,
                                        including the advertising features of
                                        Google Analytics. This is a web
                                        analytics service provided by Google
                                        Ireland Limited, Google Building Gordon
                                        House, Barrow St, Dublin 4, Ireland
                                        (hereinafter referred to as "Google").
                                        Google Analytics uses cookies, which are
                                        small text data stored on your computer,
                                        enabling analysis of website traffic.
                                        <br />
                                        <br />
                                        Below is an overview of the purposes of
                                        data collected by Google Analytics and
                                        related functionalities:
                                        <br />
                                        <ul className="list-disc pl-6">
                                            <li>
                                                Measurement of the reach of the
                                                Collabothon website.
                                            </li>
                                            <li>
                                                Optimization of the Collabothon
                                                website.
                                            </li>
                                        </ul>
                                        <br />
                                        Google Analytics data is processed based
                                        on consent according to Art. 6 Para. 1
                                        lit. a GDPR.
                                        <br />
                                        <br />
                                        Commerzbank informs you in advance that
                                        on this website, Google Analytics is
                                        extended by the code “anonymizeIp,”
                                        ensuring the anonymous collection of IP
                                        addresses (IP masking). Google Analytics
                                        is thus only used in conjunction with an
                                        activated anonymous IP. This means: the
                                        IP address of a user is shortened by
                                        Google for users within the member
                                        states of the European Union and other
                                        contracting states of the Agreement on
                                        the European Economic Area. Only in
                                        exceptional cases (e.g., a technical
                                        defect in the European Union) will the
                                        IP address be shortened outside the
                                        European Economic Area, where data could
                                        be processed in third countries without
                                        adequate data protection levels, such as
                                        in the USA. The data is then split and
                                        stored in Google data centers worldwide.
                                        More detailed information on the
                                        locations can be found at the following
                                        link:
                                        <br />
                                        https://www.google.com/about/datacenters/locations/index.html
                                        <br />
                                        <br />
                                        The method used by Google to anonymize
                                        IP addresses does not write IP addresses
                                        to the hard drive, as anonymization
                                        occurs directly after receiving the
                                        request in the RAM. The IP address
                                        transmitted in the browser is also not
                                        combined with other data from Google.
                                    </Paragraph>
                                </Accordion>
                            </Accordion.Group>
                        </div>
                        <div className="flex justify-center ">
                            <div className="flex mt-10 justify-center items-center content-center gap-10 relative p-10">
                                <Button onClick={() => handleAcceptAll()}>
                                    Accept
                                </Button>
                                <Button onClick={() => handleDecline()}>
                                    Decline
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConsentBanner;
