import React from 'react';

import RegistrationButton from '../registration-info/RegistrationButton-copy';
import Headline from '../../common/Headline';
import PrizeContainer from './PrizeContainer-copy';

const Prizes = () => {
    return (
        <div className="py-12 md:py-20 bg-primary2">
            <Headline
                headline="Win up to 10.000€ in awesome prizes"
                classNames="text-primary1"
            />
            <div className="flex flex-wrap justify-center">
                <div className="w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'2.500€'}
                        title={'<ChallengeWinners />'}
                        subtitle={'in-kind - per team'}
                    />
                </div>
                <div className="border-dashed border-t border-b xs:border-none w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'5.000€'}
                        title={'<MainWinner />'}
                        subtitle={'in-kind - one team'}
                    />
                </div>
                <div className="w-3/4 md:w-fit">
                    <PrizeContainer
                        prize={'2.500€'}
                        title={'<AudienceFavourite />'}
                        subtitle={'in-kind - one team'}
                    />
                </div>
            </div>
            <>
                <div className="flex md:hidden justify-center items-center pt-12">
                    <RegistrationButton
                        small
                        buttonLabel={'Register now'}
                    />
                </div>
                <div className="hidden md:flex justify-center items-center pt-12">
                    <RegistrationButton buttonLabel={'Register now'} />
                </div>
            </>
        </div>
    );
};

export default Prizes;
