import React from 'react';

const ThisYearChallange = () => {
    const onClick = () => {
        window.location.href = '/challenges';
    };

    return (
        <div className="py-12 md:py-20 bg-primary2">
            <h1
                className="text-4xl xs:text-4xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-8xl 3xl:text-8xl font-bold uppercase hyphens-none"
                style={{ textAlign: 'center' }}
            >
                <span className="text-white">This year's </span>
                <span className="text-[#E6007E] font-extrabold">
                    <i>challenge</i>
                </span>
            </h1>
            <p
                className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white"
                style={{ textAlign: 'left', margin: 'auto', width: '65%' }}
            >
                Collabothon features an exhilarating challenge in digital banking solutions  proposed by our Commerzbank's experts. Details start online and fully unfold on hackathon day—where the true magic unfolds! Develop a standout idea to impress judges and compete for the grand prize. Rest assured, qualified mentors will support you onsite if you need assistance.
            </p>
            <p
                className="mt-6 md:mt-12 text-lg lg:text-md xl:text-xl 2xl:text-2xl hyphens-none text-white"
                style={{ textAlign: 'left', margin: 'auto', width: '65%' }}
            >
                <div className="flex justify-center sm:justify-end pt-4 sm:pt-0 items-center text-sm w-full sm:w-auto">
                    <button
                        type="button"
                        className="p-4 px-8 rounded-full bg-primary1 hover:bg-primary1Highlighted duration-200 ease-in-out text-primary2 font-bold cursor-pointer"
                        onClick={onClick}
                    >
                        Read more!
                    </button>
                </div>
            </p>
        </div>
    );
};

export default ThisYearChallange;
