import React from 'react';
import styled from 'styled-components';

const Font = styled.div`
    font-family: var(--lsg-font-family-400, 'Gotham', sans-serif);
`;

const OrderOfEvents = ({ data, classNames }) => {
    return (
        <Font className={classNames}>
            {data.map((item, index) => (
                <div
                    key={index}
                    className="grid grid-cols-12"
                >
                    <div
                        id="numberContainer"
                        className="col-span-1 flex justify-center"
                    >
                        <div className="relative">
                            <div
                                className="absolute w-[45px] h-[45px] -left-[18px] lg:w-[55px] lg:h-[55px] lg:-left-[24px] z-20 flex justify-center items-center 
                            rounded-full bg-[#FF018C] text-white font-extrabold text-2xl md:text-3xl lg:text-4xl"
                            >
                                {item.number}
                            </div>
                            {index < data.length - 1 && (
                                <div
                                    className={`absolute w-[8px] lg:w-[10px] z-10 bg-[#FF018C] top-0 rounded-full h-full mt-5`}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        id="infoContainer"
                        className={`col-span-11 lg:col-span-10 text-lg md:text-xl xl:text-2xl 2xl:text-3xl font-bold text-primary2 mb-5 ml-10 mt-3 md:mt-5`}
                    >
                        {item.title}
                        <div
                            className={`rounded-lg overflow-hidden shadow-md bg-[#002E3C] transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/50 mt-1 lg:mt-3`}
                        >
                            <div
                                className={`text-[#DBE2E5] p-4 lg:px-7 xl:px-10 xl:py-6 block font-normal hyphens-none text-lg lg:text-md xl:text-xl 2xl:text-2xl`}
                            >
                                {item.descriptionBeforeLink}
                                {item.link &&
                                    item.linkText &&
                                    (!item.expireDate ||
                                        new Date() <
                                            new Date(item.expireDate)) && (
                                        <>
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                className="text-primary1 underline_link"
                                            >
                                                {item.linkText}
                                            </a>
                                        </>
                                    )}
                                {item.descriptionAfterLink}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Font>
    );
};
export default OrderOfEvents;
