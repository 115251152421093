export const faqData = [
    {
        id: 0,
        question: 'Why is the event called Collabothon?',
        answer: 'The event aims to facilitate collaborations across different industries, inspiring each other to explore, transform, and exploit cross-industry knowledge to create and develop innovative solutions. Thus, the teams can consist of a mix of employees of corporations, start-ups, and scientific institutions, as well as other individuals such as coders, entrepreneurs and FinTech Evangelists to foster the spirit of cross-industry collaboration.',
    },
    {
        id: 1,
        question: 'When will the Collabothon take place?',
        answer: "We'd love to see you on 20th to 22nd October 2023 – beginning from 9 am CEST. Check out our location specific schedule on our locations page.",
    },
    {
        id: 2,
        question: 'Where will the Collabothon take place?',
        answer: 'This year we decided for a fully on-site formula. The event takes place at the same time simultaneously in 4 locations: Łódź (Poland), Sofia (Bulgaria), Prague (Czech Republic) and Frankfurt am Main (Germany).',
    },
    {
        id: 3,
        question: 'What will you be doing at the event?',
        answer: 'You will develop innovative solutions that disrupt the borders between different industries. Have a look on our challenges page to explore our coding challenges. Note: We expect everything you develop to be fresh code and Sherlock Holmes along with some other detectives will ensure this during the event.',
    },
    {
        id: 4,
        question: 'How will the teams be arranged?',
        answer: 'Teams should consist of at least three and a maximum of six members. We embrace diverse team compositions, encompassing designers, project managers, coders, and more. However, the final choice is entirely up to you.',
    },
    {
        id: 5,
        question: 'Will there be any food & beverages provided?',
        answer: "Don't worry, you won't stay hungry! You will find a selection of snacks and beverages (including veg ones). There is also unlimited caffeine!",
    },
    {
        id: 6,
        question: 'What are the prize amounts for the winning teams?',
        answer: 'Each challenge will have one winning team per location, with a prize of €2500.',
    },
    {
        id: 7,
        question: 'Is there an additional grand winner prize?',
        answer: 'The local jury will select one of the winning teams to be the grand winner per location, receiving an extra €5000 prize.',
    },
    {
        id: 8,
        question: 'Is there a prize based on audience voting?',
        answer: 'Yes, the local audience will vote on presenting teams, and the team with the most votes will win an additional €2500 prize.',
    },
    {
        id: 9,
        question: 'What does it mean when prizes are referred to as "in-kind"?',
        answer: "Due to regulatory considerations as a bank, we are unable to provide cash prizes. Instead, we offer prizes in the form of 'in-kind' rewards, such as amazon.com vouchers.",
    },
];
