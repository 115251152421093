import React from 'react';

const Gem = () => (
    <span>
        <svg width="48px" height="48px" focusable="false" fill="#E6007E" id="lsgs-b1d71--icon-lvgd22vl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <title id="lsgs-b1d71--icon-lvgd22vl-title">gemstone</title>
            <path d="M35 4H13L1 16l23 31 23-31zM11.5 17H7v-2h4.5zm16 22l-3.4 4.51L31.67 17H16.33l7.57 26.51L20.52 39l-6.59-23.1L18.88 6h2.24l-4.5 9h14.76l-4.5-9h2.24l4.5 9H41v2h-7.25z"></path>
        </svg>
    </span>
);


const PrizeContainer = ({ prize, title, subtitle }) => {
    return (
        <div className="px-2 py-6 md:p-12">
            <div
                className="flex justify-center items-center
                            text-[#E6007E] text-3xl md:text-5xl font-extrabold"
            >
                <div className="hidden md:block">
                    <Gem/>

                </div>
                <div className="md:hidden">
                    <Gem/>
                </div>
                <div className="ml-4">{prize}</div>
            </div>
            <div
                className="flex justify-center align-middle 
                            text-[#002E3C] text-xl xs:text-2xl md:text-3xl font-extrabold py-4"
            >
                {title}
            </div>
            <div
                className="flex justify-center align-middle 
                            italic text-secondary2 text-lg xs:text-xl md:text-2xl font-extrabold"
            >
            </div>
        </div>
    );
};

export default PrizeContainer;
