import React from 'react';
import {Tabs, Theme} from '@lsg/components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDays} from '@fortawesome/free-solid-svg-icons';

import Timeline from './Timeline';
import {days} from './eventScheduleData';
import {isMobile} from "react-device-detect";
import Headline from "../common/Headline";


const EventSchedule = ({data}) => {
    const getDateTab = (dateString) => {
        const date = new Date(dateString);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const dayOfWeek = date.toLocaleDateString('en-US', {weekday: isMobile ? 'short' : 'long'});
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        return `${day}.${month}. / ${dayOfWeek} `;
    };

    return (
        <div
            id="event-schedule"
            className="w-full md:px-12 xl:px-24 2xl:px-48"
        >
            <Headline
                headline={
                    <div className="flex justify-center items-center my-auto">
                        <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="text-6xl mr-5"
                            color= '#E6007E'
                        />
                       <span style={{ color: '#E6007E' }}>Schedule</span>
                    </div>
                }
                classNames="text-primary1"
            />
            <div className="pb-3 rounded-xl px-4">
                <Theme look={"dark"} color="blue">
                    <Tabs.Stateful centeredLayout={true}>
                        {days.map((value) => (
                            <Tabs.Tab
                                key={value.id}
                                label={`${getDateTab(value.date)}`}
                            >
                                <Timeline
                                    data={data[value.dayOfWeek.toLowerCase()]}
                                />
                            </Tabs.Tab>
                        ))}
                    </Tabs.Stateful>
                </Theme>
            </div>
        </div>
    );
};

export default EventSchedule;
