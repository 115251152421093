import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedDiv = ({
    child,
    parentVariant,
    parentClasses,
    isBidirectional = false,
}) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        } else {
            if (isBidirectional) control.start('hidden');
        }
    }, [control, inView]);

    return (
        <motion.div
            ref={ref}
            variants={parentVariant}
            initial="hidden"
            animate={control}
            className={parentClasses}
        >
            {child}
        </motion.div>
    );
};

export default AnimatedDiv;
