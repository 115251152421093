import React, { useRef } from 'react';
import EventSchedule from '../components/event-schedule/EventSchedule';
import { eventsData } from '../components/event-schedule/eventScheduleData';

const Schedule = () => {
    const schedule = useRef(null);
    const selectedCountry = 'Poland';

    const events = eventsData.find(
        (events) => events.country === selectedCountry
    )?.schedule;

    return (
        <>
            {selectedCountry && (
                <div className="bg-primary2" ref={schedule}>
                    <div
                        className="max-w-screen-2xl mx-auto sm:pt-16 pb-8 sm:pb-16 px-4"
                        id="eventSchedule"
                    >
                        <EventSchedule data={events} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Schedule;
